import * as Sentry from '@sentry/browser';
import { firestoreHelper, functionsHelper } from '../firebase/firebase';
const { functions, httpsCallable } = functionsHelper;
/**
 * Get Atomic Financial access token.
 *
 * @returns {Promise<*>}
 */
export const getAccessToken = () => {
  const getAccessToken = httpsCallable(
    functions,
    'groupVerifyAtomicFinancial-getAccessToken'
  );

  return getAccessToken()
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error(error);
    });
};

/**
 * Helper to build employers select list.
 *
 * @param tag
 * @returns {*}
 */
export const loadEmployers = (tag = null) => {
  const { db, collection, query, orderBy, getDocs, limit, where } =
    firestoreHelper;
  // TODO: PUT into Firestore access rules.
  // match /atomicFinancialEmployers/{document=**} {
  //   allow read: if request.auth.uid != null
  // }
  const firestoreCollection = collection(db, 'atomicFinancialEmployers');
  const constraints = [];
  if (tag) {
    constraints.push(where('tag', '==', tag));
  }
  constraints.push(orderBy('name'));
  constraints.push(limit(100));

  const q = query(firestoreCollection, ...constraints);
  return getDocs(q)
    .then((querySnapshot) => {
      let employers = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        employers.push({ label: data?.name, value: data?.id });
      });

      // Return employers.
      return employers;
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error(error);
    });
};
