import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styles } from '../../../../styles';

const styleOverride = {};

const Question = (props) => {
  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.quizContainer}>
        <Typography variant="h4" component="p">
          {props.questionTitle}
        </Typography>
        <div className="form-item-select">
          <Typography variant="h6" component="h3">
            {props.question}
          </Typography>
          <div className={classes.quizQuestionContainer}>
            {Object.keys(props.options).map((key, index) => {
              const id = key;
              const questionAnswer = props.options[key];

              return (
                <div
                  className={
                    classes.quizQuestion +
                    ' ' +
                    (props.answer
                      ? props.answer.value === id
                        ? classes.quizQuestionSelected
                        : classes.quizQuestionDisabled
                      : '')
                  }
                  key={index}
                  data-id={`quiz-answer-${index}`}
                  onClick={() =>
                    props.onChange({ label: questionAnswer, value: id })
                  }
                >
                  {questionAnswer}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Button
        onClick={props.onClick}
        className={classes.button}
        variant={props.answer ? 'outlined' : 'contained'}
        disabled={props.btnDisabled}
        color="primary"
        disableElevation
        fullWidth
        size="large"
        data-id="quiz-btn"
      >
        {props.staticText.QuizQuestionBtn}
      </Button>
      <Button
        onClick={props.onSkip}
        disabled={props.skipsDisabled}
        className={!props.skipsDisabled ? classes.buttonClean : classes.hide}
        color="primary"
        disableElevation
        fullWidth
        size="large"
        data-id="quiz-skip"
      >
        {props.staticText.QuizQuestionBtnSkip}
      </Button>
    </React.Fragment>
  );
};

export default Question;
