import React, { useEffect, useState } from 'react';
import { helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styles } from '../../../styles';

const PrivacyAgreement = () => {
  const styleOverride = {};

  const history = useHistory();
  const dispatch = useDispatch();

  const customStyles = { ...styles.defaultStaticStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticText = useSelector((state) => state.textsState.data);

  const [disableNextStep, setDisableNextStep] = useState(false);

  useEffect(() => {
    document.body.classList.add('page-blue');

    return () => {
      document.body.classList.remove('page-blue');
    };
    // eslint-disable-next-line
  }, [])

  const acceptTerms = (event) => {
    event.preventDefault();

    setDisableNextStep(true);

    helpers
      .addAccountInfo(
        {
          flags: {
            privacyAgreement: true,
          },
        },
        authUser.uid
      )
      .then(() => {
        history.push(routes.STATIC_WHY_WE_ASK);
      })
      .catch((error) => {
        setDisableNextStep(false);
        helpers.handleError(error, dispatch);
      });
  };

  return (
    <div className="page-privacy-agreement">
      <Box
        p={{ xs: 2, md: 10 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.box}
      >
        <Typography className={classes.h1Big} component="h1">
          <span className={classes.spacer} />
          {staticText.privacyTitle}
        </Typography>
        <div className={classes.centerContent + ' ' + classes.alignContentTop}>
          <Typography variant="body2" component="p">
            {staticText.privacySubtitle1}
          </Typography>
          <Typography variant="body2" component="p">
            {staticText.privacySubtitle2}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>{staticText.privacyBody}</strong>
          </Typography>
        </div>
        <Button
          className={classes.button}
          disabled={disableNextStep}
          type="submit"
          disableElevation
          onClick={acceptTerms}
          fullWidth
          size="large"
          data-id="privacy-agreement-accept"
        >
          {staticText.privacyBtn}
        </Button>
      </Box>
    </div>
  );
};

export default PrivacyAgreement;
