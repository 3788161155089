import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import Button from '@mui/material/Button';
import { saveIdentification } from '../../../../actions/helpers';

const UploadFileRelative = (props) => {
  const { classes, fileCount, fileUpload, upload, staticText } = props;

  let documents = staticText.ManualVerificationRelativeDocuments;

  /**
   * Set Manual Verification as incomplete.
   */
  useEffect(() => {
    saveIdentification({ type: 'manual' }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  const maxSize = 10 * 1048576; // 1048576 - 1MB.

  const uploadTitle = staticText.ManualVerificationRelativeUploadTitle
    ? staticText.ManualVerificationRelativeUploadTitle
    : 'Upload proof of military affiliation';
  const uploadSubTitle = staticText.ManualVerificationRelativeUploadSubTitle
    ? staticText.ManualVerificationRelativeUploadSubTitle
    : 'Please upload one of the following documents:';

  const fullDocumentsList =
    staticText.ManualVerificationRelativeFullDocsListText
      ? staticText.ManualVerificationRelativeFullDocsListText
      : 'See full list of approved documents here';
  const fullDocumentsListLink =
    staticText.ManualVerificationRelativeFullDocsListLink
      ? staticText.ManualVerificationRelativeFullDocsListLink
      : 'https://help.veteransadvantage.com/en_us/us-valid-military-documents-SJ8m1RaBK';

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.h1} component="h1">
        {uploadTitle}
      </Typography>
      <Typography
        variant="h2"
        className={classes.h2 + ' ' + classes.h2Wider}
        component="h2"
      >
        {uploadSubTitle}
      </Typography>
      <div
        className={
          classes.centerContent +
          ' ' +
          classes.alignContentTop +
          ' ' +
          classes.higherContent
        }
      >
        {Object.keys(documents).map((key) => {
          return (
            <Typography key={key} component="p" className={classes.listItem}>
              {documents[key]}
            </Typography>
          );
        })}
        <Typography component="p" className={classes.listItem}>
          <a href={fullDocumentsListLink} target={'_blank'} rel="noreferrer">
            {fullDocumentsList}
          </a>
        </Typography>
      </div>
      <DropzoneArea
        onChange={fileUpload}
        filesLimit={6}
        maxFileSize={maxSize}
        acceptedFiles={[
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/svg',
          'application/pdf',
        ]}
        showPreviews={false}
        showPreviewsInDropzone={true}
        useChipsForPreview
        dropzoneClass={classes.dropzone}
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText={staticText.ManualVerificationUploadDropTitle}
        dropzoneText={staticText.ManualVerificationUploadDropSubTitle}
        inputProps={{
          'data-id': 'manual-verification-dropzone',
        }}
      />
      {fileCount === 0 && (
        <div className={classes.dropzoneAnnotation}>
          <p>{staticText.ManualVerificationUploadDropAnnotation}</p>
          <p>{staticText.ManualVerificationUploadDropAnnotation2}</p>
        </div>
      )}
      <Button
        className={classes.button}
        disableElevation
        onClick={upload}
        disabled={fileCount === 0}
        fullWidth
        size="large"
        data-id="manual-verification-upload"
      >
        {staticText.ManualVerificationUploadBtn}
      </Button>
    </React.Fragment>
  );
};

export default UploadFileRelative;
