import React, { useEffect, useState } from 'react';
import { helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BackButton from '../../Common/BackButton';
import { styles } from '../../../styles';
import KustomerWebWidget from '../../Common/KustomerWebWidget';

const WhyWeAsk = () => {
  const styleOverride = {
    fontMediumBold: {
      fontWeight: '700',
    },
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const customStyles = { ...styles.defaultStaticStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticText = useSelector((state) => state.textsState.data);

  const [disableNextStep, setDisableNextStep] = useState(false);

  useEffect(() => {
    document.body.classList.add('page-blue');

    return () => {
      document.body.classList.remove('page-blue');
    };
    // eslint-disable-next-line
  }, [])

  const acceptTerms = () => {
    // Disable accept button.
    setDisableNextStep(true);

    // Perform db query to store flag.
    helpers
      .addAccountInfo(
        {
          flags: {
            whyWeAsk: true,
          },
        },
        authUser.uid
      )
      .then(() => {
        history.push(routes.PERSONAL_INFORMATION);
      })
      .catch((error) => {
        setDisableNextStep(false);
        helpers.handleError(error, dispatch);
      });
  };

  let dataAsked = staticText.whyWeAskDataAsked;

  return (
    <div className={classes.smallBodyMobile}>
      <Box
        p={{ xs: 2, md: 10 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.box}
      >
        <BackButton disabled={disableNextStep} />

        <Typography className={classes.h1} component="h1">
          <span className={classes.spacer} />
          {staticText.whyWeAskTitle}
        </Typography>
        <div className={classes.centerContent + ' ' + classes.alignContentTop}>
          <Typography component="p" className={classes.fontMediumBold}>
            {staticText.whyWeAskSubTitle}
          </Typography>
          <Typography component="ul" className={classes.ul}>
            {Object.keys(dataAsked).map((key) => {
              return <li key={key}>{dataAsked[key]}</li>;
            })}
          </Typography>
        </div>
        <Button
          className={classes.button}
          disabled={disableNextStep}
          disableElevation
          onClick={acceptTerms}
          fullWidth
          size="large"
          data-id="why-we-ask-accept"
        >
          {staticText.whyWeAskBtn}
        </Button>
        <KustomerWebWidget
          type={'link'}
          title={staticText.whyWeAskLink}
          classes={classes}
          disabled={disableNextStep}
        />
      </Box>
    </div>
  );
};

export default WhyWeAsk;
