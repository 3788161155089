import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { saveIdentification } from '../../../../actions/helpers';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

const SuccessScreen = (props) => {
  const { classes, contactUs, staticText } = props;

  const configData = useSelector((state) => state.configState.config);
  const myAccountLinkText = staticText?.ManualVerificationMyAccountLinkText
    ? staticText?.ManualVerificationMyAccountLinkText
    : 'Return to your account';
  const myAccountLinkPath = staticText?.ManualVerificationMyAccountLinkPath
    ? staticText?.ManualVerificationMyAccountLinkPath
    : '/profile';
  /**
   * Set Manual Verification as pending.
   */
  useEffect(() => {
    saveIdentification({ type: 'manual', status: 'pending' }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.h1} component="h1">
        {staticText.ManualVerificationSuccessTitle}
      </Typography>
      <Typography variant="h2" className={classes.h2} component="h2">
        {staticText.ManualVerificationSuccessSubTitle}
      </Typography>
      <div className={classes.centerContent + ' ' + classes.contentDone}>
        <Typography component="p" variant="body1">
          {staticText.ManualVerificationSuccessBody}
        </Typography>
        <Typography component="p" variant="body1">
          {staticText.ManualVerificationSuccessBody2}
        </Typography>
        <Typography component="p" variant="h3">
          <strong>{staticText.ManualVerificationSuccessBody3}</strong>
          {staticText.ManualVerificationSuccessBody4}
        </Typography>
        <Typography component="p" variant="h3">
          <strong>{staticText.ManualVerificationSuccessBody5}</strong>
          <Link href={'#'} onClick={contactUs} className={classes.inheritLink}>
            {staticText.ManualVerificationSuccessBody6}
          </Link>
        </Typography>
        <Button
          className={classes.button}
          disableElevation
          href={staticText.ManualVerificationSuccessMarketplaceUrl}
          target={'_blank'}
        >
          {staticText.ManualVerificationSuccessMarketplaceTitle}
        </Button>
        <Button
          className={classes.buttonOutlined}
          disableElevation
          href={configData?.accountAppUrl + myAccountLinkPath}
          target={'_blank'}
        >
          {myAccountLinkText}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SuccessScreen;
