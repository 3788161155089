import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'react-mui-dropzone';
import Button from '@mui/material/Button';
import { saveIdentification } from '../../../../actions/helpers';

const UploadFile = (props) => {
  const { classes, fileCount, documents, fileUpload, upload, staticText } =
    props;

  /**
   * Set Manual Verification as incomplete.
   */
  useEffect(() => {
    saveIdentification({ type: 'manual' }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  const maxSize = 10 * 1048576; // 1048576 - 1MB.

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.h1} component="h1">
        {staticText.ManualVerificationUploadTitle}
      </Typography>
      <Typography variant="h2" className={classes.h2} component="h2">
        {staticText.ManualVerificationUploadSubTitle}
      </Typography>
      <div className={classes.centerContent + ' ' + classes.alignContentTop}>
        {Object.keys(documents).map((key) => {
          return (
            <Typography key={key} component="p" className={classes.listItem}>
              {documents[key]}
            </Typography>
          );
        })}
      </div>
      <DropzoneArea
        onChange={fileUpload}
        filesLimit={6}
        maxFileSize={maxSize}
        acceptedFiles={[
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/svg',
          'application/pdf',
        ]}
        showPreviews={false}
        showPreviewsInDropzone={true}
        useChipsForPreview
        dropzoneClass={classes.dropzone}
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText={staticText.ManualVerificationUploadDropTitle}
        dropzoneText={staticText.ManualVerificationUploadDropSubTitle}
        inputProps={{
          'data-id': 'manual-verification-dropzone',
        }}
      />
      {fileCount === 0 && (
        <div className={classes.dropzoneAnnotation}>
          <p>{staticText.ManualVerificationUploadDropAnnotation}</p>
          <p>{staticText.ManualVerificationUploadDropAnnotation2}</p>
        </div>
      )}
      <Button
        className={classes.button}
        disableElevation
        onClick={upload}
        disabled={fileCount === 0}
        fullWidth
        size="large"
        data-id="manual-verification-upload"
      >
        {staticText.ManualVerificationUploadBtn}
      </Button>
    </React.Fragment>
  );
};

export default UploadFile;
