import React, { useEffect, useState } from 'react';
import { helpers } from '../../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as routes from '../../../constants/routes';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../styles';
import {
  getAffinity,
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';

const BackButton = (props) => {
  const { disabled, idVerification } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const sessionDB = useSelector((state) => state.sessionDBState);

  const useStyles = makeStyles(() => ({ ...styles.backButtonStyles }));
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case routes.QUIZ:
      case routes.ID_SCAN:
      case routes.MANUAL_VERIFICATION:
        setVisible(false);
        break;
      case routes.CONTACT_US:
        // Do not show Back button if:
        // - Id Scan session was initiated.
        // - User failed Veriff.

        // eslint-disable-next-line no-case-declarations
        const { veriffStatus } = getAllIdentificationStatuses(accountData);
        if (
          typeof sessionDB.data.verificationIdSessionId !== 'undefined' ||
          veriffStatus === 'failed'
        ) {
          setVisible(false);
        } else {
          setVisible(true);
        }
        break;
      default:
        setVisible(true);
        break;
    }
  }, [location.pathname, accountData, sessionDB]);

  const goBack = () => {
    switch (location.pathname) {
      case routes.STATIC_WHY_WE_ASK:
        helpers
          .addAccountInfo(
            {
              flags: {
                privacyAgreement: false,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.STATIC_PRIVACY_AGREEMENT);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.PERSONAL_INFORMATION:
        helpers
          .addAccountInfo(
            {
              flags: {
                whyWeAsk: false,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.STATIC_WHY_WE_ASK);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.AFFINITY:
        helpers
          .addAccountInfo(
            {
              flags: {
                personalInformation: false,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.PERSONAL_INFORMATION);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.AFFIDAVIT:
        history.push(routes.AFFINITY);
        break;
      case routes.ATOMIC_FINANCIAL_VERIFICATION:
      case routes.MILITARY_CREDENTIALS:
      case routes.COMMISSIONED:
        helpers
          .addAccountInfo(
            {
              affidavit: false,
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.AFFIDAVIT);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.ID_SCAN:
        helpers
          .addAccountInfo(
            {
              flags: {
                showMilCred: true,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.AFFINITY);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.ACTIVE_DUTY:
        helpers
          .addAccountInfo(
            {
              flags: {
                showMilCred: true,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.AFFINITY);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.QUIZ:
        if (!idVerification) {
          const affinity = getAffinity(accountData);
          if (affinity === 'academy') {
            history.push(routes.ACADEMY);
          } else if (affinity === 'relative') {
            helpers
              .addAccountInfo(
                {
                  flags: {
                    showMilCred: true,
                  },
                },
                authUser.uid
              )
              .then(() => {
                history.push(routes.AFFINITY);
              })
              .catch((error) => {
                helpers.handleError(error, dispatch);
              });
          }
        } else {
          history.push(routes.ID_SCAN);
        }
        break;
      case routes.ACADEMY:
        helpers
          .addAccountInfo(
            {
              flags: {
                showMilCred: true,
              },
            },
            authUser.uid
          )
          .then(() => {
            history.push(routes.AFFINITY);
          })
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });
        break;
      case routes.CONTACT_US:
        history.goBack();
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {visible && (
        <IconButton
          disabled={disabled}
          aria-label="back"
          className={classes.back}
          onClick={goBack}
          data-id="back-btn"
        >
          <ArrowBackIosIcon fontSize="medium" />
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default BackButton;
