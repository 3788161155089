const INITIAL_STATE = {
  data: {
    branches: null,
    ranks: null,
    eras: null,
    paygrades: null,
    units: null,
    relationships: null,
  },
};

function militaryInfoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_MILINFO': {
      return { data: { ...state.data, ...action.payload } };
    }
    default:
      return state;
  }
}

export default militaryInfoReducer;
