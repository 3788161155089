import React, { useEffect } from 'react';
import { useSegmentPageTracking } from '../../hooks';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from '../../constants/routes';

function CustomContainer(props) {
  const history = useHistory();
  const location = useLocation();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const signOutUser = useSelector(
    (state) => state.sessionDBState.data.signOutUser
  );

  useEffect(() => {
    if (!authUser && location['pathname'] !== routes.SIGN) {
      history.push(routes.SIGN);
    }
  }, [authUser]);

  /**
   * Redirect to sign out when needed.
   */
  useEffect(() => {
    if (signOutUser && location['pathname'] !== routes.SIGN) {
      history.push(routes.SIGN);
    }
  }, [signOutUser]);

  useSegmentPageTracking();

  return (
    <Container className="app" disableGutters>
      {props.children}
    </Container>
  );
}

export default CustomContainer;
