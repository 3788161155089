import React from 'react';
import Typography from '@mui/material/Typography';

const CaptureInProgress = (props) => (
  <React.Fragment>
    <Typography variant="h1" component="h1">
      {props.staticText.IdScanCaptureInProgressTitle}
    </Typography>
    <Typography variant="h2" component="h2">
      {props.staticText.IdScanCaptureInProgressSubTitle}
    </Typography>
    <div className={props.classes.centerContent}></div>
  </React.Fragment>
);

export default CaptureInProgress;
