const INITIAL_STATE = {
  status: 'loading',
  account: null,
  create: false,
};

const applySetAccounts = (state, action) => ({
  status: 'loaded',
  account: action.payload,
  create: false,
});

function accountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FETCH_ACCOUNT': {
      return action.payload ? applySetAccounts(state, action) : INITIAL_STATE;
    }
    case 'FETCH_ACCOUNT_CREATE': {
      return {
        ...state,
        create: action.payload,
      };
    }
    default:
      return state;
  }
}

export default accountReducer;
