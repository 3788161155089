import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analytics, helpers } from '../../../actions';
import * as Sentry from '@sentry/browser';

const KustomerWebWidget = (props) => {
  const { classes, disabled, type, title } = props;

  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticText = useSelector((state) => state.textsState.data);
  const accountData = useSelector((state) => state.accountState.account);

  const [status, setStatus] = useState('start');
  const [initiateSign, setInitiateSign] = useState(false);

  useEffect(() => {
    const kustomerInit = () => {
      // eslint-disable-next-line no-undef
      Kustomer.start(
        {
          brandId: process.env.REACT_APP_KUSTOMER_API_BRAND,
        },
        function () {
          setStatus('done');
        }
      );
    };

    if (status === 'done') {
      openChat();
      window.removeEventListener('kustomerLoaded', kustomerInit);
    }

    window.addEventListener('kustomerLoaded', kustomerInit);
    return () => window.removeEventListener('kustomerLoaded', kustomerInit);
  }, [status]);

  useEffect(() => {
    if (status === 'done' && authUser && !initiateSign) {
      setInitiateSign(true);

      helpers
        .getJWTKustomerToken()
        .then((token) => {
          window.Kustomer.login(
            {
              jwtToken: token.data,
            },
            (loginCallbackResponse, error) => {
              if (error) {
                Sentry.captureException(error);
                console.error(error);
              }
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, [status, authUser])

  const new_script = (src, id, dataKustomerApiKey) => {
    return new Promise(function (resolve, reject) {
      let script = document.createElement('script');
      script.src = src;
      script.id = id;
      if (dataKustomerApiKey) {
        script.setAttribute('data-kustomer-api-key', dataKustomerApiKey);
      }
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  };

  const do_load = () => {
    if (status !== 'done' && typeof window.Kustomer === 'undefined') {
      setStatus('loading');

      let kustomer_script = new_script(
        'https://cdn.kustomerapp.com/chat-web/widget.js',
        'kustomer-snippet',
        process.env.REACT_APP_KUSTOMER_API_KEY
      );

      kustomer_script.catch((error) => {
        helpers.handleError(error, dispatch);
      });
    } else {
      openChat();
    }
  };

  const openChat = () => {
    // eslint-disable-next-line no-undef
    Kustomer.open();

    analytics.track('Support Clicked', {
      memberId: accountData?.memberId,
      widgetPage: window.location.href,
      context: {
        app: {
          name: 'VerifyApp',
          namespace: 'components/Support',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    });
  };

  if (type === 'link') {
    return (
      <Button
        className={classes.buttonClean}
        disabled={disabled}
        disableElevation
        onClick={do_load}
        fullWidth
        size="large"
        data-id="why-we-ask-decline"
      >
        {status === 'loading' && staticText.HelpLoading}
        {status !== 'loading' && title}
      </Button>
    );
  } else {
    return (
      <Button
        aria-label="back"
        className={classes.help}
        onClick={do_load}
        data-id="help-btn"
      >
        <HelpOutlineIcon fontSize="medium" />
        {status === 'loading' && staticText.HelpLoading}
        {status !== 'loading' && staticText.footerHelpBtn}
      </Button>
    );
  }
};

export default KustomerWebWidget;
