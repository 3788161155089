import React, { useSelector } from 'react-redux';
import Preloader from '../../../Preloader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { vh } from '../../../../styles/helper';
import { styles } from '../../../../styles';
import { ATOMIC_VERIFICATION_DELAY } from '../../../../constants/atomicFinancial';
import { useEffect, useState } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';

const AtomicLoading = (props) => {
  const { type } = props;

  const [counter, setCounter] = useState(ATOMIC_VERIFICATION_DELAY / 1000);

  const staticText = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    pageTitle: {
      marginBottom: 0,
    },
    pageLoader: {
      margin: '0 auto',
    },
    pageDescription: {
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.666667px'),
      textAlign: 'center',
    },
    pageCounter: {
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.666667px'),
      fontWeight: 700,
      textAlign: 'center',
      '& .icon': {
        verticalAlign: 'middle',
        fontSize: vh('20px'),
        color: theme.palette.primary.main,
        marginRight: vh('5px'),
      },
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        marginLeft: vh('-20px'),
        marginRight: vh('-20px'),
      },
    },
  }));
  const classes = useStyles();

  const atomicFinancialLoadingBackendTitle =
    staticText?.AtomicFinancialLoadingBackendTitle ??
    'Instant Verification in progress';
  const atomicFinancialLoadingBackendDescription =
    staticText?.AtomicFinancialLoadingBackendDescription ??
    'Please give us a moment to retrieve your results.';
  const atomicFinancialLoadingBackendCounter =
    staticText?.AtomicFinancialLoadingBackendCounter ??
    `Your estimated time remaining is ${counter}s`;

  const atomicFinancialLoadingTitle =
    staticText?.AtomicFinancialLoadingTitle ??
    'Establishing a secure connection...';
  const atomicFinancialLoadingDescription =
    staticText?.AtomicFinancialLoadingDescription ??
    'This will only take a moment.';

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {type === 'backend'
          ? atomicFinancialLoadingBackendTitle
          : atomicFinancialLoadingTitle}
      </Typography>
      {/*Wait for loading for ATOMIC_VERIFICATION_DELAY + 10 seconds to give time to set status*/}
      <div className={classes.pageLoader}>
        <Preloader
          inline
          errorTitle="Error loading Atomic Financial"
          waitFor={ATOMIC_VERIFICATION_DELAY + 10000}
        />
      </div>
      <div className={classes.pageDescription}>
        {type === 'backend'
          ? atomicFinancialLoadingBackendDescription
          : atomicFinancialLoadingDescription}
      </div>
      {type === 'backend' && (
        <div className={classes.pageCounter}>
          <ScheduleIcon className="icon" />
          {atomicFinancialLoadingBackendCounter}
        </div>
      )}
    </Box>
  );
};

export default AtomicLoading;
