import React, { useState } from 'react';
import { helpers } from '../../../actions';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styles } from '../../../styles';
import getCustomRoute from '../../../actions/getCustomRoute';
import { updateSession } from '../../../actions/db';
import { vh } from '../../../styles/helper';

const WelcomeScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    boxHeigher: {
      justifyContent: 'flex-start',
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        minHeight: 0,
        height: 'auto',
      },
    },
    pageTitle: {
      marginBottom: vh('24px'),
    },
    pageDescription: {
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.666667px'),
      textAlign: 'center',
      marginBottom: vh('48px'),
    },
    buttonBottom: {
      marginTop: 0,
    },
  }));
  const classes = useStyles();

  const staticText = useSelector((state) => state.textsState.data);

  const [disableNextStep, setDisableNextStep] = useState(false);

  const acceptTerms = async () => {
    // Disable accept button.
    setDisableNextStep(true);

    // Perform db query to store flag.
    try {
      dispatch(
        updateSession({
          hash: null,
        })
      );

      getCustomRoute(history);
    } catch (error) {
      setDisableNextStep(false);
      helpers.handleError(error, dispatch);
    }
  };

  const welcomeScreenTitle =
    staticText.welcomeScreenTitle ??
    'We noticed you haven’t completed your military verification.';
  const welcomeScreenDescription =
    staticText.welcomeScreenDescription ??
    'Don’t worry, it’s a few simple steps and then you’ll be on your way to upgrading your plan!';
  const welcomeScreenButton =
    staticText.welcomeScreenButton ?? 'Continue and Verify';

  return (
    <div className={classes.smallBodyMobile}>
      <Box
        p={{ xs: 2, md: 10 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.box + ' ' + classes.boxHeigher}
      >
        <Typography variant="h1" component="h1" className={classes.pageTitle}>
          {welcomeScreenTitle}
        </Typography>
        <div className={classes.pageDescription}>
          {welcomeScreenDescription}
        </div>
        <Button
          className={classes.button + ' ' + classes.buttonBottom}
          disabled={disableNextStep}
          disableElevation
          onClick={acceptTerms}
          fullWidth
          size="large"
          data-id="welcome-screen-button"
        >
          {welcomeScreenButton}
        </Button>
      </Box>
    </div>
  );
};

export default WelcomeScreen;
