import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const RelativeCredentials = (props) => {
  const {
    branch,
    branches,
    timeServed,
    timesServed,
    relationship,
    relationships,
    handleBranchChange,
    handleTimeServedChange,
    handleOptionChangeRelationship,
    staticText,
  } = props;

  const relativeCredentialsRelationshipLabel =
    staticText.RelativeCredentialsRelationshipLabel
      ? staticText.RelativeCredentialsRelationshipLabel
      : 'What is your relationship to this family member?';
  const relativeCredentialsRelationshipPlaceholder =
    staticText.RelativeCredentialsRelationshipPlaceholder
      ? staticText.RelativeCredentialsRelationshipPlaceholder
      : 'Select Relationship';

  return (
    <React.Fragment>
      <div className="form-item-select">
        <label>{relativeCredentialsRelationshipLabel}</label>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={relationship}
          options={relationships}
          getOptionLabel={(option) => option.label}
          onChange={handleOptionChangeRelationship}
          ListboxProps={{ 'data-id': 'relative-credentials-relationship-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={relativeCredentialsRelationshipPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'relative-credentials-relationship',
              }}
            />
          )}
        />
      </div>
      <div className="form-item-select">
        <label>{staticText.RelativeCredentialsBranchLabel}</label>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={branch}
          options={branches}
          getOptionLabel={(option) => option.label}
          onChange={handleBranchChange}
          ListboxProps={{ 'data-id': 'relative-credentials-branch-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.RelativeCredentialsBranchPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'relative-credentials-branch',
              }}
            />
          )}
        />
      </div>
      <div className="form-item-select">
        <label>{staticText.RelativeCredentialsEraLabel}</label>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={timeServed}
          options={timesServed}
          getOptionLabel={(option) => option.label}
          onChange={handleTimeServedChange}
          ListboxProps={{ 'data-id': 'relative-credentials-era-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.RelativeCredentialsEraPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'relative-credentials-era',
              }}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default RelativeCredentials;
