import React, { useState } from 'react';
import Question from './Question';

const QuestionContainer = (props) => {
  const {
    options,
    onClick,
    id,
    question,
    activeQuestion,
    total,
    onSkip,
    skips,
    staticText,
  } = props;

  const [answer, setAnswer] = useState(null);

  let answersList = [];

  options.forEach(applyListTitles);

  function applyListTitles(data, index) {
    answersList[index] = {
      label: data,
      value: index,
    };
  }

  const handleAnswerChange = (answer) => {
    setAnswer(answer);
  };

  const chooseAnswer = () => {
    onClick(id, answer);
  };

  let questionTitle = staticText.QuizQuestionTitle;

  questionTitle = questionTitle.replace('@index', activeQuestion);
  questionTitle = questionTitle.replace('@total', total);

  return (
    <Question
      questionTitle={questionTitle}
      question={question}
      answer={answer}
      onChange={handleAnswerChange}
      answersList={answersList}
      options={options}
      onClick={chooseAnswer}
      onSkip={onSkip}
      btnDisabled={!answer}
      skipsDisabled={skips >= 2}
      staticText={staticText}
    />
  );
};

export default QuestionContainer;
