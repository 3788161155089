import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../../styles';
import Button from '@mui/material/Button';
import { vh } from '../../../../styles/helper';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { saveIdentification } from '../../../../actions/helpers';
import { AddressAutocomplete } from 'components/Common/AddressAutocomplete';
import { statesOptions } from 'constants/helpers';

const LighthouseVerificationForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    pageTitle: {
      fontSize: vh('26px'),
      lineHeight: vh('39px'),
      marginBottom: vh('35px'),
    },
    logo: {
      paddingTop: vh('48px'),
      paddingBottom: vh('10px'),
      maxWidth: vh('260px'),
      margin: '0 auto',
      '& img': {
        width: '100%',
        height: 'auto',
        display: 'block',
        maxWidth: '100%',
      },
    },
    pageSubTitle: {
      margin: 0,
      '& strong': {
        display: 'block',
      },
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        marginLeft: vh('-30px'),
        marginRight: vh('-30px'),
        marginBottom: vh('12px'),
      },
    },
    field: {
      marginBottom: vh('40px'),
    },
    codeItem: {
      marginBottom: 'auto',
    },
  }));
  const classes = useStyles();

  const {
    branch,
    branches,
    onChangeBranch,
    staticTexts,
    onSubmit,
    skip,
    fieldData,
    onChange,
    isFormValid,
  } = props;

  /**
   * Set Lighthouse Verification as incomplete.
   */
  useEffect(() => {
    saveIdentification({ type: 'lighthouse' }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  return (
    <>
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {staticTexts.LighthouseV1VerificationTitle}
      </Typography>
      <div className="form-item-select">
        <label>{staticTexts.LighthouseVerificationBranchLabel}</label>
        <Autocomplete
          value={branch}
          options={branches}
          getOptionLabel={(option) => option.label}
          onChange={onChangeBranch}
          ListboxProps={{ 'data-id': 'lighthouse-branch-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticTexts.LighthouseVerificationBranchPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'lighthouse-branch',
              }}
            />
          )}
        />
      </div>
      <div className={classes.logo}>
        <img
          src={'/assets/images/veterans-affairs-logo.svg'}
          alt="US Department of Veterans Affairs Logo"
        />
      </div>
      <Typography variant="h2" component="h2" className={classes.pageSubTitle}>
        <strong>{staticTexts.LighthouseV1VerificationSubTitleBold}</strong>
      </Typography>
      <div className={classes.centerContent + ' ' + classes.alignContentCenter}>
        <div className={'form-item ' + classes.codeItem}>
          <AddressAutocomplete
            value={fieldData.street}
            name={'address autocomplete'}
            classes={classes}
            fieldData={fieldData}
            onChange={(e) => onChange('street', e.target.value)}
            onAutocompleteChange={onChange}
            label={'Street'}
            description={
              staticTexts?.LighthouseV1VerificationAdressDesc ??
              'Should include house number, street name and suffix'
            }
          />
          <TextField
            label="City"
            variant="filled"
            value={fieldData.city}
            onChange={(e) => onChange('city', e.target.value)}
            fullWidth
            className={classes.field}
            inputProps={{
              'data-id': 'lighthouse-city',
            }}
          />
          <TextField
            label="Zip Code"
            variant="filled"
            value={fieldData.zipcode}
            onChange={(e) => onChange('zipcode', e.target.value)}
            fullWidth
            inputProps={{
              maxLength: 5,
              'data-id': 'lighthouse-zip-code',
            }}
            className={classes.field}
          />
          <div className="form-item-select">
            <label>
              {staticTexts.LighthouseVerificationStateLabel ?? 'State'}
            </label>
            <Autocomplete
              options={statesOptions}
              getOptionLabel={(option) => option.label}
              value={fieldData.state}
              onChange={(event, value) => {
                return onChange(
                  'state',
                  statesOptions.find((state) => state.id === value.id)
                );
              }}
              className={classes.field}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  variant="filled"
                  fullWidth
                />
              )}
              id="lighthouse-state"
            />
          </div>
        </div>
        <Button
          onClick={onSubmit}
          className={classes.button}
          disabled={!isFormValid()}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          size="large"
          data-id="lighthouse-code-btn"
        >
          {staticTexts.LighthouseVerificationBtn}
        </Button>
      </div>
      <Button
        className={classes.buttonClean}
        disableElevation
        onClick={skip}
        fullWidth
        size="large"
        data-id="lighthouse-skip"
      >
        {staticTexts.LighthouseVerificationLink}
      </Button>
    </>
  );
};

export default LighthouseVerificationForm;
