import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styles } from '../../../styles';
import { vh } from '../../../styles/helper';

const ContactUs = () => {
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    boxHeigher: {
      justifyContent: 'flex-start',
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        minHeight: 0,
        height: 'auto',
      },
    },
    pageTitle: {
      marginBottom: vh('8px'),
    },
    pageDescription: {
      marginBottom: vh('60px'),
    },
    pageInfo: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.666667px'),
    },
    pageInfoItem: {
      marginTop: vh('32px'),
      '&:first-child': {
        marginTop: 0,
      },
    },
    pageInfoItemTitle: {
      fontWeight: 400,
      marginBottom: vh('8px'),
    },
    pageInfoItemDescription: {
      '& span': {
        color: '#001E33',
        display: 'block',
        marginTop: vh('13px'),
      },
    },
  }));
  const classes = useStyles();

  const staticText = useSelector((state) => state.textsState.data);

  const contactUsTitle =
    staticText.ContactUsTitle ?? 'Contact Support for Manual Verification';
  const contactUsSubTitle =
    staticText.ContactUsSubTitle ??
    'We’re unable to verify you at this time. Please contact our Customer Support team for assistance with manual verification.';

  const contactUsItems = staticText.ContactUsItems ?? [
    {
      title: 'Chat with us',
      description:
        'Please use the help button at the bottom of your screen to begin a chat session',
    },
    {
      title: 'Call us',
      description:
        'You can reach customer support at<br/>' +
        '1-866-VET-ASSIST (1-866-838-2774)<br/>' +
        '<span>Monday -Friday from 9AM-9PM EST</span>',
    },
    {
      title: 'Email us',
      description: 'help@veteransadvantage.com',
    },
  ];

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      <React.Fragment>
        <Typography variant="h1" component="h1" className={classes.pageTitle}>
          {contactUsTitle}
        </Typography>
        <Typography
          variant="h2"
          component="h2"
          className={classes.pageDescription}
        >
          {contactUsSubTitle}
        </Typography>
        <ul className={classes.pageInfo}>
          {contactUsItems.map((item, index) => (
            <li key={index} className={classes.pageInfoItem}>
              <div className={classes.pageInfoItemTitle}>{item.title}</div>
              <div
                dangerouslySetInnerHTML={{ __html: item.description }}
                className={classes.pageInfoItemDescription}
              />
            </li>
          ))}
        </ul>
      </React.Fragment>
    </Box>
  );
};

export default ContactUs;
