import React from 'react';
import reactStringReplace from 'react-string-replace';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { vh } from '../../../styles/helper';
import { db } from '../../../actions';
import { styles } from '../../../styles';
import * as routes from '../../../constants/routes';
import KustomerWebWidget from '../KustomerWebWidget';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0',
    paddingTop: vh('14px'),
    paddingBottom: vh('14px'),
  },
  wrapper: {
    ...styles.globalRewritesExport,
    width: '100%',
    marginLeft: 0,
    flexWrap: 'wrap',
    marginTop: vh('-12px'),
    ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
      marginTop: vh('12px'),
    },
    ['@media (min-width:' + theme.breakpoints.values.l + 'px)']: {
      flexWrap: 'nowrap',
    },
    // Override default material ui styles.
    '&.MuiGrid-spacing-xs-3 > .MuiGrid-item': {
      padding: vh('12px'),
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  terms: {
    textAlign: 'center',
    color: theme.palette.primary.gray,
    flex: '1 0 auto',
    order: '-1',
    justifySelf: 'center',
    width: '100%',
    ['@media (min-width:' + theme.breakpoints.values.l + 'px)']: {
      order: '0',
      flex: '1 0 auto',
      width: 'auto',
    },
  },
  help: {
    color: theme.palette.primary.red,
    fontWeight: 'bold',
    fontSize: vh('12px'),
    lineHeight: vh('18px'),
    textTransform: 'none',
    textAlign: 'center',
    borderRadius: vh('1px'),
    padding: vh('6px') + ' ' + vh('15px'),
    '& svg': {
      marginRight: vh('3px'),
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        marginRight: vh('10px'),
      },
    },
    ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
      background: theme.palette.primary.red,
      color: theme.palette.primary.white,
      fontWeight: 'bold',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      'min-width': vh('132px'),
      height: vh('44px'),
      marginLeft: vh('30px'),
    },
    '&:hover, &:focus': {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        background: theme.palette.primary.redDark,
      },
    },
  },
  signout: {
    color: theme.palette.primary.black,
    fontWeight: 'bold',
    fontSize: vh('12px'),
    lineHeight: vh('18px'),
    borderRadius: vh('1px'),
    textAlign: 'center',
    textTransform: 'none',
    padding: vh('6px') + ' ' + vh('15px'),
    '& svg': {
      marginRight: vh('3px'),
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        display: 'none',
      },
    },
    ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
      border: vh('1px') + ' solid ' + theme.palette.primary.gray,
      fontWeight: 'bold',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      width: vh('132px'),
      height: vh('44px'),
      marginRight: vh('30px'),
    },
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyright: {
    fontFamily: theme.typography.fontFamily,
    fontSize: vh('12px'),
    lineHeight: vh('18px'),
    textAlign: 'center',
    padding: '0 ' + vh('15px'),
  },
  alignCenter: {
    textAlign: 'center',
    margin: '12px 0',
  },
  kustomer: {
    fontSize: vh('12px'),
    lineHeight: vh('18px'),
    textTransform: 'none',
    textAlign: 'center',
    borderRadius: vh('1px'),
    padding: vh('6px') + ' ' + vh('15px'),
    ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      'min-width': vh('132px'),
      height: vh('44px'),
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const staticText = useSelector((state) => state.textsState.data);

  const setSignOut = () => {
    dispatch(
      db.setErrorPage(
        false,
        false,
        'error',
        false,
        'Yes, sign out',
        routes.SIGN_OUT,
        false,
        true,
        true,
        'Are you sure you want to sign out?'
      )
    );
  };

  let staticTextTerms =
    staticText.footerStaticTextTerms ??
    'By signing up, you agree to our @terms, @security and @data.';

  staticTextTerms = reactStringReplace(
    staticTextTerms,
    '@terms',
    (match, i) => (
      <Link
        key={i * 404}
        href={staticText.footerTermsLink}
        target={'_blank'}
        className={classes.link}
      >
        {staticText.footerTermsTitle}
      </Link>
    )
  );
  staticTextTerms = reactStringReplace(
    staticTextTerms,
    '@security',
    (match, i) => (
      <Link
        key={i * 4604}
        href={staticText.footerSecurityLink}
        target={'_blank'}
        className={classes.link}
      >
        {staticText.footerSecurityTitle}
      </Link>
    )
  );
  staticTextTerms = reactStringReplace(staticTextTerms, '@data', (match, i) => (
    <Link
      key={i * 5404}
      href="#"
      onClick={(event) => {
        event.preventDefault();
        window?.transcend?.showConsentManager({ viewState: 'CompleteOptions' });
      }}
      className={classes.link}
    >
      {staticText.footerDataTitle}
    </Link>
  ));

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems={'flex-start'}
        className={classes.wrapper}
      >
        <Grid item xs>
          <KustomerWebWidget classes={classes} />
        </Grid>
        <Grid item xs className={classes.terms}>
          <Typography component="p" className={classes.copyright}>
            {staticTextTerms}
          </Typography>
        </Grid>
        <Grid item xs className={classes.alignRight}>
          <Button
            aria-label="back"
            className={classes.signout}
            onClick={setSignOut}
            data-id="sign-out-btn"
          >
            <ExitToAppIcon fontSize="medium" />
            {staticText.footerSignOut}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Footer;
