import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../styles';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../Common/BackButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { helpers } from '../../../actions';
import getCustomRoute, { getAffinity } from '../../../actions/getCustomRoute';
import { fetchAccountInfo } from '../../../actions/db';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { vh } from '../../../styles/helper';

const styleOverride = {
  box: {
    ...styles.defaultFormStyles.box,
    '@media (min-width: 494px)': {
      minHeight: vh('782px'),
      height: 'auto',
    },
  },
  pageTitle: {
    marginBottom: vh('18px'),
  },
  affidavitText: {
    fontSize: vh('16px'),
    lineHeight: vh('24px'),
    letterSpacing: vh('0.666667px'),
    '& sup': {
      fontSize: vh('10px'),
    },
  },
  formControl: {
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginLeft: 0,
      marginRight: 0,
      '@media (min-width: 494px)': {
        marginLeft: vh('-39px'),
      },
    },
    '& .MuiIconButton-root': {
      padding: vh('0px'),
      marginRight: vh('15px'),
    },
    // Fix for V5.
    '& .MuiCheckbox-root': {
      padding: vh('0px'),
      marginRight: vh('15px'),
    },
  },
  formItemSignature: {
    marginTop: vh('24px'),
  },
  buttonSkip: {
    fontSize: vh('14px'),
    textAlign: 'left',
    padding: 0,
    width: 'auto',
    marginTop: vh('5px'),
  },
  button: {
    ...styles.defaultFormStyles.button,
    marginTop: vh('16px'),
    marginBottom: vh('16px'),
  },
  refsList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    color: '#677796',
    fontSize: vh('10px'),
    lineHeight: vh('26px'),
    letterSpacing: vh('0.666667px'),
    marginTop: 'auto',
  },
};

const Affidavit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sigCanvas = useRef(null);

  // Get needed Redux values.
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticText = useSelector((state) => state.textsState.data);

  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const [affidavit, setAffidavit] = useState(false);
  const [affidavitText, setAffidavitText] = useState(
    staticText.AffidavitAttestText
  );
  const [affidavitTextRefs, setAffidavitTextRefs] = useState(
    staticText.AffidavitAttestTextRefs
  );
  const [signature, setSignature] = useState(
    accountData?.signature ? accountData?.signature : null
  );
  const [containerSignature, setContainerSignature] = useState({
    width: 334,
    height: 80,
  });
  const [disableNextStep, setDisableNextStep] = useState(true);
  const [disableSignature, setDisableSignature] = useState(false);

  useEffect(() => {
    // Protect unauthorized access.
    if (authUser) {
      // Load latest accountData with updated affinity.
      dispatch(fetchAccountInfo(authUser.uid));
    }
  }, [authUser, history]);

  const onSignatureEnd = () => {
    setSignature(sigCanvas.current.toDataURL());
  };

  function debounce(fn, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const signatureDraw = (width, height) => {
    if (signature) {
      sigCanvas.current.fromDataURL(signature, {
        width: width,
        height: height,
      });
    }
  };

  useEffect(() => {
    const containerSignatureElm = document.getElementById(
      'form-item-signature'
    );

    setContainerSignature({
      width: containerSignatureElm.offsetWidth,
      height: containerSignatureElm.offsetHeight,
    });

    signatureDraw(
      containerSignatureElm.offsetWidth,
      containerSignatureElm.offsetHeight
    );
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const containerSignatureElm = document.getElementById(
      'form-item-signature'
    );

    const debouncedHandleResize = debounce(function handleResize() {
      setContainerSignature({
        width: containerSignatureElm.offsetWidth,
        height: containerSignatureElm.offsetHeight,
      });

      signatureDraw(
        containerSignatureElm.offsetWidth,
        containerSignatureElm.offsetHeight
      );
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    const affinity = getAffinity(accountData);
    if (affinity) {
      switch (affinity) {
        case 'relative':
          setAffidavitText(staticText.AffidavitAttestTextFamily);
          setAffidavitTextRefs(staticText.AffidavitAttestTextFamilyRefs);
          break;
        case 'academy':
        case 'active_duty':
        case 'commissioned':
          setAffidavitText(staticText.AffidavitAttestTextActive);
          setAffidavitTextRefs(staticText.AffidavitAttestTextActiveRefs);
          break;
        default:
          setAffidavitText(staticText.AffidavitAttestText);
          setAffidavitTextRefs(staticText.AffidavitAttestTextRefs);
          break;
      }
    }
    // eslint-disable-next-line
  }, [accountData])

  /**
   * Helper to control affidavit checkbox and next button behavior.
   */
  const handleAffidavidChange = () => {
    setAffidavit(!affidavit);
    setDisableNextStep(!disableNextStep);
  };

  /**
   * Next step helper.
   */
  const nextStep = () => {
    setDisableNextStep(true);
    // Save user properties.
    helpers
      .addAccountInfo(
        {
          affidavit: true,
          signature: signature,
        },
        authUser.uid
      )
      .then(() => {
        accountData.affidavit = true;
        getCustomRoute(history, accountData);
      })
      .catch((error) => {
        setDisableNextStep(false);
        helpers.handleError(error, dispatch);
      });
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <BackButton />
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {staticText.AffidavitTitle}
      </Typography>
      {disableSignature && (
        <FormControl className={classes.formControl} variant="filled">
          <FormControlLabel
            control={
              <Checkbox
                checked={affidavit}
                onChange={handleAffidavidChange}
                name="affidavit"
                id="affidavit"
                color="primary"
              />
            }
            label={
              <span
                className={classes.affidavitText}
                dangerouslySetInnerHTML={{ __html: affidavitText }}
              />
            }
          />
        </FormControl>
      )}
      {!disableSignature && (
        <>
          <div
            className={classes.affidavitText}
            dangerouslySetInnerHTML={{ __html: affidavitText }}
          />
          <div className={classes.formItemSignature}>
            <label>{staticText.AffidavitSignLabel}</label>
            <div
              id={'form-item-signature'}
              className={classes.signatureWrapper}
            >
              <SignatureCanvas
                ref={sigCanvas}
                onEnd={onSignatureEnd}
                penColor="blue"
                canvasProps={{
                  ...containerSignature,
                  className: 'sigCanvas ' + classes.signature,
                  'data-id': 'military-credentials-signature',
                }}
              />
            </div>
            <Button
              className={classes.buttonClean + ' ' + classes.buttonSkip}
              disableElevation
              onClick={() => setDisableSignature(true)}
              fullWidth
              size="large"
              data-id="affidavit-sign-skip"
            >
              {staticText.AffidavitSignSkip}
            </Button>
          </div>
        </>
      )}
      <div className={'footer'}>
        <Button
          className={classes.button}
          type="submit"
          disableElevation
          onClick={nextStep}
          disabled={
            (!disableSignature && !signature) ||
            (disableSignature && disableNextStep)
          }
          fullWidth
          size="large"
          data-id="military-credentials-next"
        >
          {staticText?.MilitaryCredentialsBtn
            ? staticText?.MilitaryCredentialsBtn
            : 'Next Step'}
        </Button>
      </div>
      {affidavitTextRefs && (
        <ul className={classes.refsList}>
          {affidavitTextRefs.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default Affidavit;
