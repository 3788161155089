import { vh } from './helper';

const colors = {
  trueBlack: '#000',
  white: '#fff',
};
const Akkurat = ['"Akkurat"', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const breakpoint = {
  desktop: 494,
  largeDesktop: 960,
};

export const theme = {
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 30, 51, 0.6)',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F8F9FA',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#001E33',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#001E33',
        },
      },
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: '#001E33',
      black: '#001E33',
      red: '#001E33',
      redDark: '#677796',
      gray: '#001E33',
      white: colors.white,
      green: '#2ED47A',
    },
    secondary: {
      main: '#001E33',
    },
    header: {
      main: '#F0F1F4',
    },
    error: {
      main: '#FF3C5F',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: breakpoint.desktop,
      l: breakpoint.largeDesktop,
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px ' +
      vh('3px') +
      ' ' +
      vh('5px') +
      ' ' +
      vh('-1px') +
      ' rgba(0,0,0,0.2),0px ' +
      vh('5px') +
      ' ' +
      vh('8px') +
      ' 0px rgba(0,0,0,0.14),0px ' +
      vh('1px') +
      ' ' +
      vh('14px') +
      ' 0px rgba(0,0,0,0.12)',
    '0px ' +
      vh('11px') +
      ' ' +
      vh('15px') +
      ' rgba(0, 0, 0, 0.2), 0px ' +
      vh('9px') +
      ' ' +
      vh('46px') +
      ' rgba(0, 0, 0, 0.12), 0px ' +
      vh('24px') +
      ' ' +
      vh('38px') +
      ' rgba(0, 0, 0, 0.14)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    h1: {
      fontFamily: Akkurat,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: vh('32px'),
      lineHeight: vh('48px'),
      textAlign: 'center',
      letterSpacing: vh('-1px'),
      padding: '0 ' + vh('10px'),
      ['@media (min-width:' + breakpoint.desktop + 'px)']: {
        padding: '0',
      },
    },
    h2: {
      fontWeight: 'normal',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      textAlign: 'center',
      letterSpacing: vh('0.666667px'),
      '& + h2': {
        marginTop: vh('24px'),
      },
    },
    h3: {
      fontWeight: 'normal',
      fontSize: vh('20px'),
      lineHeight: vh('30px'),
    },
    h4: {
      fontWeight: 'bold',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.777778px'),
    },
    h6: {
      fontWeight: 'normal',
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      letterSpacing: vh('0.777778px'),
    },
    body1: {
      fontFamily: Akkurat,
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      color: '#001E33',
    },
    body2: {
      fontFamily: Akkurat,
      fontWeight: 'normal',
      fontSize: vh('18px'),
      lineHeight: vh('26px'),
      'p&': {
        marginBottom: vh('26px'),
      },
      'p&:last-child': {
        marginBottom: '0',
      },
      ['@media (min-width:' + breakpoint.desktop + 'px)']: {
        fontSize: vh('18px'),
        lineHeight: vh('26px'),
      },
    },
    copyright: {
      fontFamily: Akkurat,
      fontSize: vh('12px'),
      lineHeight: vh('18px'),
      textAlign: 'center',
      ['@media (min-width:' + breakpoint.desktop + 'px)']: {
        lineHeight: vh('14px'),
      },
    },
    subtitle2: {
      fontFamily: Akkurat,
      fontSize: vh('10px'),
      lineHeight: vh('15px'),
      textAlign: 'center',
      fontStyle: 'italic',
    },
    fontFamily: Akkurat,
  },
};

// Global Box and rewrites.
const globalRewrites = {
  // Overrides to default sizes of material UI.
  '& .MuiSvgIcon-root': {
    fontSize: vh('24px'),
  },
  '& .MuiFilledInput-input': {
    paddingTop: vh('27px'),
    paddingLeft: vh('12px'),
    paddingRight: vh('12px'),
    paddingBottom: vh('10px'),
    // Fix for V5.
    height: '1.1876em',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
    {
      padding: vh('9px') + ' ' + vh('4px'),
    },
  '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
    {
      marginTop: vh('16px'),
    },
  '& .MuiInputAdornment-positionStart': {
    marginRight: vh('8px'),
  },
  '& .MuiIconButton-root': {
    padding: vh('12px'),
    fontSize: vh('24px'),
  },
  // Fix for V5.
  '& .MuiRadio-root': {
    padding: vh('12px'),
    fontSize: vh('24px'),
  },
  '& .MuiAutocomplete-popupIndicator': {
    padding: vh('2px'),
    marginRight: vh('-2px'),
  },
  '& .MuiAutocomplete-clearIndicator ': {
    padding: vh('4px'),
    marginRight: vh('-2px'),
  },
  '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]':
    {
      paddingRight: vh('65px'),
    },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
    paddingTop: vh('19px'),
    paddingLeft: vh('8px'),
  },
  '& .MuiFilledInput-root': {
    borderTopLeftRadius: vh('4px'),
    borderTopRightRadius: vh('4px'),
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment':
    {
      right: vh('9px'),
    },
  '& .MuiAutocomplete-endAdornment': {
    top: 'calc(50% - ' + vh('14px') + ')',
  },
  '& .MuiFormHelperText-contained': {
    marginLeft: vh('14px'),
    marginRight: vh('14px'),
  },
  '& .MuiFormHelperText-root': {
    fontSize: vh('12px'),
  },
  '& .MuiInputLabel-filled': {
    transform: 'translate(' + vh('12px') + ', ' + vh('17px') + ') scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(' + vh('12px') + ', ' + vh('7px') + ') scale(0.75)',
    },
  },
  '& .MuiDropzoneArea-root': {
    marginTop: vh('24px'),
    minHeight: vh('300px'),
    borderRadius: vh('4px'),
    padding: vh('10px'),
  },
  '& .MuiDropzoneArea-text': {
    marginTop: vh('24px'),
    marginBottom: vh('24px'),
    paddingLeft: vh('67px'),
    paddingRight: vh('67px'),
    fontSize: vh('14px'),
    lineHeight: vh('21px'),
    textAlign: 'center',
    letterSpacing: vh('0.68px'),
  },
  '& .MuiDropzoneArea-textContainer': {
    marginTop: vh('40px'),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  '& .MuiDropzoneArea-icon': {
    width: vh('51px'),
    height: vh('51px'),
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: vh('-11px'),
    marginRight: vh('-16px'),
  },
};
// Append this to the container if you don't use default styles (for example: footer)
export const globalRewritesExport = globalRewrites;

const globalBox = {
  ...globalRewrites,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  borderRadius: vh('4px'),
  padding: vh('16px') + ' ' + vh('23px'),
  minHeight: vh('700px'),
  maxWidth: vh('491px'),
  ['@media (min-width:' + breakpoint.desktop + 'px)']: {
    maxWidth: 'none',
    width: vh(breakpoint.desktop),
    height: vh('782px'),
    padding: vh('72px'),
  },
  '& label:not(.MuiFormLabel-root):not(.MuiFormControlLabel-root)': {
    display: 'block',
    fontSize: vh('16px'),
    lineHeight: vh('24px'),
    letterSpacing: vh('0.15px'),
    marginBottom: vh('10px'),
    color: '#001E33',
  },
  '& .MuiFilledInput-underline': {
    '&:before': {
      display: 'none',
    },
  },
};

const button = {
  display: 'flex',
  flexDirection: 'column',
  padding: vh('15px') + ' ' + vh('50px'),
  width: '100%',
  height: vh('54px'),
  color: colors.white,
  background: '#001E33',
  borderRadius: vh('1px'),
  fontFamily: Akkurat,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: vh('16px'),
  lineHeight: vh('24px'),
  marginTop: vh('32px'),
  textTransform: 'none',
  '&[disabled], &:disabled': {
    background: '#F0F1F4',
    color: '#B3BBCA',
  },
  '&:hover, &:focus': {
    background: '#677796',
    textDecoration: 'none',
  },
};

const buttonOutlined = {
  ...button,
  background: 'transparent',
  border: vh('1px') + ' solid ' + '#001E33',
  color: '#001E33',
  paddingLeft: vh('20px'),
  paddingRight: vh('20px'),
  '&:hover, &:focus': {
    borderColor: '#677796',
    background: '#677796',
    color: colors.white,
  },
};

const buttonHuge = {
  ...button,
  height: 'auto',
  fontSize: vh('24px'),
  lineHeight: vh('36px'),
  padding: vh('30px') + ' ' + vh('48px'),
};

const buttonRed = {
  ...button,
  background: '#001E33',
  '&:hover, &:focus': {
    background: '#677796',
  },
};
const buttonClean = {
  textTransform: 'none',
  padding: vh('15px') + ' ' + vh('20px'),
  fontSize: vh('16px'),
  lineHeight: vh('24px'),
  color: '#677796',
  background: colors.white,
  textDecoration: 'underline',
  '&:hover, &:focus': {
    background: colors.white,
    textDecoration: 'none',
  },
};

const buttonCleanSmall = {
  ...buttonClean,
  padding: vh('8px') + ' 0',
  fontSize: vh('14px'),
  lineHeight: vh('24px'),
  color: '#001E33',
  background: colors.white,
  textDecoration: 'none',
  '&:hover, &:focus': {
    background: colors.white,
    textDecoration: 'underline',
  },
};

const centerContent = {
  flex: '1 0 auto',
  paddingTop: vh('32px'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  '& fieldset': {
    height: '100%',
    display: 'flex',
    alignContent: 'stretch',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      // Get the radio button to align top.
      '& label': {
        alignItems: 'flex-start',
        margin: vh('5px') + ' 0',
        '& > span:first-child': {
          alignSelf: 'flex-start',
          position: 'relative',
          top: vh('-14px'),
        },
      },
    },
  },
};

const contentAbsoluteCenter = {
  flex: '1 0 auto',
  paddingTop: vh('32px'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const alignContentCenter = {
  justifyContent: 'flex-start',
};
const alignContentTop = {
  justifyContent: 'flex-start',
};

const alignContentSpaceBetween = {
  justifyContent: 'space-between',
};

export const defaultFormStyles = {
  box: globalBox,
  centerContent: centerContent,
  contentAbsoluteCenter: contentAbsoluteCenter,
  alignContentTop: alignContentTop,
  alignContentCenter: alignContentCenter,
  alignContentSpaceBetween: alignContentSpaceBetween,
  container: {
    display: 'flex',
  },
  title: {
    marginBottom: vh('10px'),
    letterSpacing: vh('-0.14px'),
  },
  description: {
    marginBottom: vh('25px'),
    color: '#6D6C6B',
  },
  marginHelper: {
    marginTop: vh('5px'),
    marginBottom: vh('11px'),
  },
  checkbox: {
    marginTop: vh('-6px'),
    marginBottom: vh('0px'),
  },
  field: {
    borderBottom: '0 none',
    '&:before': {
      display: 'none',
    },
  },
  phoneControl: {
    marginBottom: vh('20px'),
  },
  inheritLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: '#001E33',
      textDecoration: 'underline',
    },
  },
  hide: {
    opacity: 0,
  },
  button: button,
  buttonHuge: buttonHuge,
  buttonOutlined: buttonOutlined,
  buttonRed: buttonRed,
  buttonClean: buttonClean,
  buttonCleanSmall: buttonCleanSmall,
  onTop: {
    position: 'relative',
    zIndex: '1001',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  annotationWrapper: {
    marginBottom: vh('-80px'),
    marginTop: vh('64px'),
    color: '#626262',
  },
  phoneAnnotationWrapper: {
    color: colors.primary,
  },
  annotation: {
    display: 'block',
    textAlign: 'center',
    color: 'inherit',
    fontSize: vh('10px'),
    lineHeight: vh('15px'),
    fontStyle: 'italic',
    marginBottom: vh('10px'),
  },
  moreText: {
    display: 'block',
    textAlign: 'center',
    color: colors.trueBlack,
    fontSize: vh('10px'),
    lineHeight: vh('15px'),
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  moreLink: {
    display: 'block',
    textAlign: 'center',
    color: '#001E33',
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
    fontSize: vh('10px'),
    lineHeight: vh('15px'),
    marginBottom: vh('10px'),
  },
  datePicker: {
    display: 'block',
    '& > div': {
      width: '100%',
      borderBottom: '0 none',
      '&:before': {
        display: 'none',
      },
    },
    // Fix for V5.
    '& .MuiIconButton-root': {
      marginLeft: 0,
    },
  },
  dateField: {
    width: '100%',
  },
  attestText: {
    marginTop: vh('24px'),
    marginBottom: vh('20px'),
    display: 'block',
    fontSize: vh('16px'),
    lineHeight: vh('24px'),
    letterSpacing: vh('0.666667px'),
  },
  moveTopSmall: {
    marginTop: vh('-10px'),
  },
  signatureWrapper: {
    display: 'block',
    width: '100%',
    height: vh('80px'),
  },
  signature: {
    display: 'block',
    background: '#F8F9FA',
    borderRadius:
      vh('4px') + ' ' + vh('4px') + ' ' + vh('0px') + ' ' + vh('0px'),
  },
  copyright: {
    fontSize: vh('12px'),
    lineHeight: vh('18px'),
    textAlign: 'center',
    letterSpacing: vh('0.666667px'),
    marginTop: vh('32px'),
  },
  quizContainer: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: vh('20px'),
  },
  quizQuestionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  quizQuestion: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: vh('160px'),
    maxWidth: '49%',
    minHeight: vh('160px'),
    marginTop: vh('8px'),
    padding: vh('8px'),
    fontWeight: 'bold',
    fontSize: vh('14px'),
    lineHeight: vh('16px'),
    textAlign: 'center',
    cursor: 'pointer',
    ['@media (min-width:' + breakpoint.desktop + 'px)']: {
      marginTop: vh('28px'),
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      display: 'block',
      width: '100%',
      height: '100%',
      border: vh('1px') + ' solid ' + '#677796',
    },
  },
  quizQuestionSelected: {
    boxShadow:
      vh('0px') + ' ' + vh('4px') + ' ' + vh('8px') + ' rgba(0,0,0,0.4)',
    '&:before': {
      content: '""',
      height: vh('4px'),
      backgroundColor: '#677796',
      border: '0 none',
    },
  },
  quizQuestionDisabled: {
    background: '#F8F9FA',
    '&:before': {
      display: 'none',
    },
  },
  counterIcon: {
    color: '#2ED47A',
    fontSize: vh('56px') + '!important',
    marginBottom: vh('30px'),
  },
  counter: {
    fontWeight: 'bold',
    fontSize: vh('16px'),
    lineHeight: vh('24px'),
    textAlign: 'center',
    color: '#001E33',
    '& span': {
      color: '#001E33',
    },
  },
  contentDone: {
    justifyContent: 'center',
    textAlign: 'center',
    '& p': {
      marginBottom: vh('40px'),
    },
    '& .MuiTypography-h3': {
      marginLeft: vh('-30px'),
      marginRight: vh('-30px'),
    },
  },
  contentDoneBottom: {
    justifyContent: 'flex-end',
    textAlign: 'center',
    '& p': {
      marginBottom: vh('40px'),
    },
    '& .MuiTypography-h3': {
      marginLeft: vh('-30px'),
      marginRight: vh('-30px'),
    },
  },
};

export const defaultStaticStyles = {
  box: globalBox,
  centerContent: centerContent,
  alignContentTop: alignContentTop,
  alignContentCenter: alignContentCenter,
  spacer: {
    display: 'block',
    height: vh('60px'),
  },
  h1: {
    fontFamily: Akkurat,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: vh('28px'),
    lineHeight: vh('37px'),
    letterSpacing: '0.666667px',
  },
  h1Big: {
    fontFamily: Akkurat,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: vh('28px'),
    lineHeight: vh('37px'),
    letterSpacing: vh('0.666667px'),
    ['@media (min-width:' + breakpoint.desktop + 'px)']: {
      fontSize: vh('33px'),
    },
  },
  h2: {
    marginTop: vh('24px'),
  },
  smallBodyMobile: {
    fontFamily: Akkurat,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: vh('16px'),
    lineHeight: vh('26px'),
    'p&': {
      marginBottom: vh('26px'),
    },
    ['@media (min-width:' + breakpoint.desktop + 'px)']: {
      fontSize: vh('18px'),
      lineHeight: vh('26px'),
    },
  },
  ul: {
    listStyle: 'none',
    margin: vh('32px') + ' ' + vh('0'),
    padding: '0',

    '& li': {
      position: 'relative',
      marginBottom: '0.3em',

      '&:before': {
        content: "'•'",
        position: 'relative',
        width: '1em',
        display: 'inline-block',
      },
    },
  },
  button: button,
  buttonClean: buttonClean,
};

export const backButtonStyles = {
  back: {
    position: 'absolute',
    top: vh('14px'),
    left: vh('5px'),
    justifyContent: 'center',
    color: '#001E33',
    ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
      position: 'absolute',
      left: vh('8px'),
      top: vh('23px'),
    },
  },
};

export const defaultAlertStyles = {
  container: {
    borderRadius: '0',
    padding: vh('30px') + ' ' + vh('25px'),
  },
  alert: {
    textAlign: 'left',
    fontSize: vh('14px'),
    lineHeight: vh('21px'),
    overflowWrap: 'break-word',
    overflow: 'auto',
    '& .MuiAlert-message': {
      width: 'calc(100% - ' + vh('30px') + ')',
    },
  },
  alertTitle: {
    fontSize: vh('18px'),
    lineHeight: vh('27px'),
    fontWeight: 'bold',
  },
  title: {
    fontSize: vh('18px'),
    lineHeight: vh('27px'),
    fontWeight: 'bold',
    padding: vh('0') + ' ' + vh('23px'),
  },
  alertDescLink: {
    color: 'rgb(70, 0, 12)',
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
  },
  desc: {
    fontSize: vh('14px'),
    lineHeight: vh('21px'),
    padding: vh('17px') + ' ' + vh('20px'),
    overflowWrap: 'break-word',
  },
  button: button,
  buttonHuge: buttonHuge,
  buttonOutlined: buttonOutlined,
  buttonRed: buttonRed,
  buttonClean: buttonClean,
  buttonCleanSmall: buttonCleanSmall,
};

export const defaultIdScanStyles = {
  idContainer: {
    flex: '1 0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  card: {
    position: 'relative',
    width: vh('160px'),
    marginTop: vh('8px'),
    fontSize: vh('14px'),
    lineHeight: vh('21px'),
    textAlign: 'center',
    ['@media (min-width:' + breakpoint.desktop + 'px)']: {
      marginTop: vh('24px'),
    },
    '&:before': {
      content: '""',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: vh('136px'),
      height: vh('88px'),
      marginBottom: vh('5px'),
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
    },
    '&.card-driver:before': {
      backgroundImage: 'url("/assets/images/icon-drivers.svg")',
    },
    '&.card-passport:before': {
      backgroundImage: 'url("/assets/images/icon-passport.svg")',
    },
    '&.card-permit:before': {
      backgroundImage: 'url("/assets/images/icon-permit.svg")',
    },
    '&.card-id:before': {
      backgroundImage: 'url("/assets/images/icon-card.svg")',
    },
  },
};
