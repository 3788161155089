import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Typography, DialogTitle, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { vh } from '../../../../styles/helper';
import HelpIcon from '@mui/icons-material/Help';

function AtomicTooltip() {
  const useStyles = makeStyles((theme) => ({
    tooltipIcon: {
      color: '#677796',
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'middle',
      '& .icon': {
        fontSize: vh('14px'),
      },
    },
    atomicTooltipDialog: {
      borderRadius: 0,
      maxWidth: vh('501px'),
      padding: vh('72px') + ' ' + vh('60px'),
    },
    atomicTooltipDialogHeader: {
      padding: 0,
      marginBottom: vh('30px'),
    },
    atomicTooltipDialogHeaderClose: {
      fontSize: vh('24px'),
      position: 'absolute',
      right: vh('20px'),
      top: vh('20px'),
      padding: 0,
      color: '#000',
    },
    atomicTooltipDialogTitle: {
      color: '#001E33',
      textAlign: 'left',
      fontSize: vh('26px'),
      lineHeight: vh('39px'),
    },
    atomicTooltipDialogContent: {
      padding: 0,
    },
    atomicTooltipDialogSubTitle: {
      color: theme.palette.primary.main,
      textAlign: 'left',
      marginBottom: vh('20px'),
    },
    atomicTooltipDialogContentText: {
      fontSize: vh('16px'),
      lineHeight: vh('24px'),
      color: '#677796',
      '& ul': {
        margin: 0,
        paddingLeft: vh('35px'),
        listStyle: 'decimal',
        '& li': {
          marginBottom: vh('14px'),
        },
      },
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const staticText = useSelector((state) => state.textsState.data);

  const atomicTooltipTitle =
    staticText?.AtomicTooltipTitle ??
    'Linking your proof of employment with Veterans Advantage';
  const atomicTooltipSubtitle =
    staticText?.AtomicTooltipSubtitle ?? 'Verification in 3 simple steps';
  const atomicTooltipText = staticText?.AtomicTooltipText ?? [
    'Select your employer',
    'Login to your payroll provider',
    'Select data on your paycheck is automatically reviewed for proof of employment and instant verification.',
  ];
  return (
    <>
      <div onClick={() => setOpen(true)} className={classes.tooltipIcon}>
        <HelpIcon className="icon" fontSize="inherit" />
      </div>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.atomicTooltipDialog }}
      >
        <DialogTitle
          className={classes.atomicTooltipDialogHeader}
          id="customized-dialog-title"
          disableTypography
        >
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.atomicTooltipDialogHeaderClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography
            variant="h1"
            component="h1"
            className={classes.atomicTooltipDialogTitle}
          >
            {atomicTooltipTitle}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.atomicTooltipDialogContent}>
          <Typography
            variant="h2"
            component="h2"
            className={classes.atomicTooltipDialogSubTitle}
          >
            {atomicTooltipSubtitle}
          </Typography>
          <div className={classes.atomicTooltipDialogContentText}>
            <ul>
              {atomicTooltipText.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AtomicTooltip;
