import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as routes from '../../../constants/routes';
import BackButton from '../../Common/BackButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { vh } from '../../../styles/helper';
import { styles } from '../../../styles';
import * as Sentry from '@sentry/browser';
import getCustomRoute, {
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { fetchAccountInfo } from '../../../actions/db';
import {
  getAccessToken,
  loadEmployers,
} from '../../../actions/atomicFinancial';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Atomic, Product } from '@atomicfi/transact-javascript';
import { saveIdentification } from '../../../actions/helpers';
import { helpers } from '../../../actions';
import AtomicContent from './AtomicContent';
import AtomicTooltip from './AtomicTooltip';
import AtomicLoading from './AtomicLoading';
import Preloader from '../../Preloader';
import { ATOMIC_VERIFICATION_DELAY } from '../../../constants/atomicFinancial';

const AtomicFinancial = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticText = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('30px'),
        paddingBottom: vh('30px'),
        minHeight: vh('782px'),
        height: 'auto',
      },
    },
    pageTitle: {
      fontSize: vh('26px'),
      lineHeight: vh('35px'),
      marginBottom: vh('14px'),
    },
    pageSubTitle: {
      color: '#001E33',
      marginBottom: vh('34px'),
    },
  }));
  const classes = useStyles();

  const atomicFinancialTitle =
    staticText?.AtomicFinancialTitle ??
    'Link Proof of Employment with Veterans Advantage for Instant Verification';
  const atomicFinancialSubTitle =
    staticText?.AtomicFinancialSubTitle ??
    'Verify your Active Duty status instantly through your employer.';
  const atomicFinancialSelectLabel =
    staticText?.AtomicFinancialSelectLabel ?? 'Select your employer';
  const atomicFinancialSelectPlaceholder =
    staticText?.AtomicFinancialSelectPlaceholder ?? 'Employer';
  const atomicFinancialButton =
    staticText?.AtomicFinancialsBtn ?? 'Agree and continue';
  const atomicFinancialSkipButton =
    staticText?.AtomicFinancialsSkipBtn ?? 'Verify Manually';

  const [loading, setLoading] = useState(false);
  const [atomicActive, setAtomicActive] = useState(false);
  const [waitingWebhookResult, setWaitingWebhookResult] = useState(false);
  const [employer, setEmployer] = useState();
  const [employers, setEmployers] = useState([]);
  const [verifyManually, setVerifyManually] = useState(false);

  let atomicEvents = [];

  useEffect(async () => {
    if (authUser) {
      const { atomicStatus } = getAllIdentificationStatuses(accountData);
      if (atomicStatus === 'pending') {
        setWaitingWebhookResult(true);
      } else {
        // Load Employers list.
        const loadedEmployers = await loadEmployers();
        setEmployers(loadedEmployers);
        saveIdentification({ type: 'atomicfi' }).catch((error) => {
          console.error(error.message);
        });
      }
    }
    // eslint-disable-next-line
  }, [authUser])

  // Helper to wait only for 30 seconds.
  useEffect(async () => {
    if (waitingWebhookResult) {
      setTimeout(() => {
        saveIdentification({ type: 'atomicfi', status: 'failed' })
          .then(() => {
            dispatch(fetchAccountInfo(authUser.uid));
            return true;
          })
          .catch((error) => {
            setLoading(false);
            helpers.handleError(error, dispatch);
          });
      }, ATOMIC_VERIFICATION_DELAY);
    }
    // eslint-disable-next-line
  }, [waitingWebhookResult])

  // Wait for Atomic Financial verification result.
  // TODO uncomment when get to this part.
  useEffect(() => {
    if (!atomicActive) {
      const { atomicStatus } = getAllIdentificationStatuses(accountData);
      // Check if status was set.
      if (atomicStatus === 'passed') {
        // Send to success screen.
        getCustomRoute(history);
      } else if (atomicStatus === 'failed') {
        if (!verifyManually) {
          history.push(routes.ATOMIC_FINANCIAL_VERIFICATION_FAILURE);
        } else {
          getCustomRoute(history);
        }
      }
    }
  }, [accountData, atomicActive, verifyManually]);

  const startAtomicFinancialVerification = async () => {
    setLoading(true);
    const accessToken = await getAccessToken();
    const config = {
      memberId: accountData?.memberId,
      companyId: employer?.value,
      token: accessToken,
    };
    initializeAtomicFinancial(config);
  };

  /**
   * Atomic Financial Transact Widget initialization on the client side.
   *
   * @param data
   */
  const initializeAtomicFinancial = (data) => {
    setAtomicActive(true);
    try {
      Atomic.transact({
        config: {
          publicToken: data.token,
          tasks: [{ product: Product.VERIFY }],
          deeplink: {
            step: 'login-company',
            companyId: data.companyId,
          },
        },
        // Atomic Financial emmits lots of events on the client side.
        // These are just some examples.
        onInteraction: async (interaction) => {
          setLoading(false);
          // Reset events array.
          if (interaction?.name === 'Viewed Login Page') {
            atomicEvents = [];
          }
          // Collect all events from popup.
          atomicEvents.push(interaction?.name);
          // Set status to pending
          if (
            interaction?.name === 'Viewed Task Completed Page' &&
            atomicEvents.indexOf('Viewed Task Failed Page') === -1 &&
            atomicEvents.indexOf('Viewed Authentication Failed Page') === -1
          ) {
            await saveIdentification({ type: 'atomicfi', status: 'pending' })
              .then(() => {
                dispatch(fetchAccountInfo(authUser.uid));
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        },
        onFinish: () => {
          dispatch(fetchAccountInfo(authUser.uid));
          setAtomicActive(false);
          setWaitingWebhookResult(true);
        },
        onClose: (data) => {
          dispatch(fetchAccountInfo(authUser.uid));
          if (!verifyManually && data?.reason) {
            if (data?.reason === 'task-pending') {
              setWaitingWebhookResult(true);
            } else {
              history.push(routes.ATOMIC_FINANCIAL_VERIFICATION_FAILURE);
            }
          } else {
            setWaitingWebhookResult(true);
            getCustomRoute(history);
          }
          setAtomicActive(false);
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const onSkip = () => {
    setLoading(true);
    setVerifyManually(true);
    saveIdentification({ type: 'atomicfi', status: 'failed' })
      .then(() => {
        dispatch(fetchAccountInfo(authUser.uid));
        return true;
      })
      .then(() => {
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        helpers.handleError(error, dispatch);
      });
  };

  return (
    <>
      {verifyManually ? (
        <Box
          p={{ xs: 2, md: 10 }}
          boxShadow={{ xs: 0, sm: 0, md: 6 }}
          className={classes.box + ' ' + classes.boxHeigher}
        >
          <Preloader errorTitle="Error loading Atomic Financial" />
        </Box>
      ) : loading ? (
        <AtomicLoading />
      ) : waitingWebhookResult ? (
        <AtomicLoading type="backend" />
      ) : (
        <Box
          p={{ xs: 2, md: 10 }}
          boxShadow={{ xs: 0, sm: 0, md: 6 }}
          className={classes.box + ' ' + classes.boxHeigher}
        >
          <BackButton />
          <Typography variant="h1" component="h1" className={classes.pageTitle}>
            {atomicFinancialTitle}
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            className={classes.pageSubTitle}
          >
            {atomicFinancialSubTitle}
            <AtomicTooltip />
          </Typography>
          <AtomicContent />
          <div className="form-item-select">
            <label>{atomicFinancialSelectLabel}</label>
            {employers && (
              <Autocomplete
                value={employer || null}
                options={employers}
                getOptionLabel={(option) => option.label}
                onChange={(event, option) => {
                  setEmployer(option);
                }}
                ListboxProps={{ 'data-id': 'atomic-financial-employers-list' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={atomicFinancialSelectPlaceholder}
                    inputProps={{
                      ...params.inputProps,
                      'data-id': 'atomic-financial-employer',
                    }}
                  />
                )}
              />
            )}
          </div>
          <div className={'footer'}>
            <Button
              className={classes.button}
              type="submit"
              disableElevation
              onClick={startAtomicFinancialVerification}
              disabled={!employer}
              fullWidth
              size="large"
              data-id="atomic-financial-verify"
            >
              {atomicFinancialButton}
            </Button>
            <Button
              onClick={onSkip}
              className={classes.buttonClean}
              disableElevation
              fullWidth
              size="large"
              data-id="atomic-financial-skip"
            >
              {atomicFinancialSkipButton}
            </Button>
          </div>
        </Box>
      )}
    </>
  );
};

export default AtomicFinancial;
