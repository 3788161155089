import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styles } from '../../../../styles';
import { saveIdentification } from '../../../../actions/helpers';

const styleOverride = {};

const AcademyForm = (props) => {
  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const {
    onSkip,
    onSubmit,
    email,
    onChangeInput,
    inputValidation,
    error,
    staticText,
  } = props;

  /**
   * Set Email Verification as Incomplete.
   */
  useEffect(() => {
    saveIdentification({
      type: 'email',
      email: '',
      status: 'incomplete',
    }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h1" component="h1">
        {staticText.AcademyFormTitle}
      </Typography>

      <div className={classes.centerContent + ' ' + classes.alignContentCenter}>
        <div className={'form-item'}>
          <label htmlFor="email">{staticText.AcademyFormEmailLabel}</label>
          <FormControl error={!!error} variant="filled" fullWidth>
            <InputLabel htmlFor="email">
              {staticText.AcademyFormEmailPlaceholder}
            </InputLabel>
            <FilledInput
              id="email"
              className={classes.field}
              value={email}
              inputProps={{
                'data-id': 'academy-email',
              }}
              onChange={(e) => onChangeInput(e)}
              onBlur={(e) => {
                inputValidation(
                  e,
                  staticText.AcademyFormEmailPlaceholder,
                  true,
                  'email'
                );
              }}
            />
            <FormHelperText className={classes.marginHelper}>
              {error ? error.message : ''}
            </FormHelperText>
          </FormControl>
        </div>

        <Button
          onClick={onSubmit}
          className={classes.button}
          variant={error ? 'outlined' : 'contained'}
          disabled={!!error || email === ''}
          color="primary"
          disableElevation
          fullWidth
          size="large"
          data-id="academy-send"
        >
          {staticText.AcademyFormSendBtn}
        </Button>
        <Button
          onClick={onSkip}
          className={classes.buttonClean}
          color="primary"
          disableElevation
          fullWidth
          size="large"
          data-id="academy-cancel"
        >
          {staticText.AcademyCodeFormSkipBtn}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AcademyForm;
