export const SIGN = '/';
export const SIGN_OUT = '/signout';
export const VERIFY = '/verify';
export const CONTACT_US = '/contact-us';
export const STATIC_PRIVACY_AGREEMENT = '/privacy-agreement';
export const STATIC_WHY_WE_ASK = '/why-we-ask';
export const PERSONAL_INFORMATION = '/personal-information';
export const AFFINITY = '/affinity';
export const AFFIDAVIT = '/affidavit';
export const MILITARY_CREDENTIALS = '/military-credentials';
export const ACTIVE_DUTY = '/active-duty';
export const COMMISSIONED = '/commissioned';
export const ACADEMY = '/academy';
export const LIGHTHOUSE_VERIFICATION = '/veterans-confirmation-api-submission';
export const PHONE_VERIFICATION = '/phone-verification';
export const ID_SCAN = '/id-scan';
export const QUIZ = '/quiz';
export const MANUAL_VERIFICATION = '/manual-verification';
export const ATOMIC_FINANCIAL_VERIFICATION = '/atomic';
export const ATOMIC_FINANCIAL_VERIFICATION_FAILURE = '/atomic-failure';
export const WELCOME_SCREEN = '/welcome';
