import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { vh } from '../../../../styles/helper';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function AtomicContent() {
  const useStyles = makeStyles(() => ({
    atomicContent: {
      paddingLeft: vh('15px'),
      marginBottom: vh('20px'),
    },
    atomicContentDataBlocks: {
      marginBottom: vh('35px'),
    },
    atomicContentDataBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: vh('24px'),
    },
    atomicContentDataIcon: {
      color: '#677796',
      flexShrink: 0,
      marginRight: vh('10px'),
      '& .icon': {
        display: 'block',
        fontSize: vh('22px'),
      },
    },
    atomicContentDataContent: {
      flexGrow: 1,
      color: '#001E33',
    },
    atomicContentDataContentTitle: {
      fontWeight: 400,
      fontSize: vh('14px'),
      lineHeight: vh('24px'),
    },
    atomicContentDataContentDesc: {
      fontSize: vh('12px'),
      lineHeight: vh('16px'),
    },
    atomicContentAccessBlock: {
      marginBottom: vh('24px'),
    },
    atomicContentAccessBlockTitle: {
      color: '#000',
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: vh('12px'),
      lineHeight: vh('16px'),
      marginBottom: vh('19px'),
    },
    atomicContentAccessBlockList: {
      padding: 0,
      margin: 0,
    },
    atomicContentAccessBlockListItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: vh('19px'),
    },
    atomicContentAccessBlockListItemIcon: {
      color: '#677796',
      flexShrink: 0,
      marginRight: vh('10px'),
      '& .icon': {
        display: 'block',
        fontSize: vh('18px'),
      },
    },
    atomicContentAccessBlockListItemContent: {
      flexGrow: 1,
      fontSize: vh('12px'),
      lineHeight: vh('16px'),
    },
  }));
  const classes = useStyles();

  const staticText = useSelector((state) => state.textsState.data);

  const atomicContentEncryptionTitle =
    staticText?.AtomicContentEncryptionTitle ?? 'Secure AES Encryption';
  const atomicContentEncryptionDesc =
    staticText?.AtomicContentEncryptionDesc ??
    'NIST certified encryption to keep your data safe.';

  const atomicContentDataProtectedTitle =
    staticText?.AtomicContentDataProtectedTitle ?? 'Your data protected';
  const atomicContentDataProtectedDesc =
    staticText?.AtomicContentDataProtectedDesc ??
    'Veterans Advantage will only have access to required information.';

  const atomicContentAllowAccessTitle =
    staticText?.AtomicContentAllowAccessTitle ?? 'Only allows access to';
  const atomicContentAllowAccessText =
    staticText?.AtomicContentAllowAccessText ?? [
      'Employment  Information  (Type, Status, Name and Job Title)',
      'Identity Information  (First Name, Last Name, Date of Birth, City, State, and Zip Code)',
    ];

  return (
    <div className={classes.atomicContent}>
      <div className={classes.atomicContentDataBlocks}>
        <div className={classes.atomicContentDataBlock}>
          <div className={classes.atomicContentDataIcon}>
            <VerifiedUserIcon className="icon" fontSize="inherit" />
          </div>
          <div className={classes.atomicContentDataContent}>
            <div className={classes.atomicContentDataContentTitle}>
              {atomicContentEncryptionTitle}
            </div>
            <div className={classes.atomicContentDataContentDesc}>
              {atomicContentEncryptionDesc}
            </div>
          </div>
        </div>
        <div className={classes.atomicContentDataBlock}>
          <div className={classes.atomicContentDataIcon}>
            <LockIcon className="icon" fontSize="inherit" />
          </div>
          <div className={classes.atomicContentDataContent}>
            <div className={classes.atomicContentDataContentTitle}>
              {atomicContentDataProtectedTitle}
            </div>
            <div className={classes.atomicContentDataContentDesc}>
              {atomicContentDataProtectedDesc}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.atomicContentAccessBlock}>
        <div className={classes.atomicContentAccessBlockTitle}>
          {atomicContentAllowAccessTitle}
        </div>
        <ul className={classes.atomicContentAccessBlockList}>
          {atomicContentAllowAccessText.map((item, index) => (
            <li
              key={index}
              className={classes.atomicContentAccessBlockListItem}
            >
              <div className={classes.atomicContentAccessBlockListItemIcon}>
                <CheckCircleIcon className="icon" fontSize="inherit" />
              </div>
              <div className={classes.atomicContentAccessBlockListItemContent}>
                {item}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AtomicContent;
