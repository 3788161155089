import React, { Component } from 'react';
import { withScope, captureException, showReportDialog } from '@sentry/browser';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { vh } from '../../styles/helper';
import { styles } from '../../styles';

const customStyles = (theme) => ({
  ...styles.defaultStaticStyles,
  errorBoundaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 260px)',
    textAlign: 'center',
  },
  errorButton: {
    color: theme.palette.primary.white,
    fontWeight: 'bold',
    fontSize: vh('16px'),
    lineHeight: vh('24px'),
    'min-width': vh('132px'),
    height: vh('44px'),
    textTransform: 'none',
    textAlign: 'center',
    borderRadius: vh('1px'),
    padding: vh('6px') + ' ' + vh('15px'),
    margin: vh('15px'),
  },
  btnReload: {
    background: theme.palette.primary.red,
    '&:hover, &:focus': {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        background: theme.palette.primary.redDark,
      },
    },
  },
});

class ErrorBoundary extends Component {
  state = { eventId: '', hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo);
      this.setState({ eventId: captureException(error) });
    });
  }

  render() {
    const { classes } = this.props;
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className={classes.errorBoundaryWrapper}>
        <h2 style={{ margin: 0 }}>Oops!</h2>
        <h4>Something went wrong</h4>
        <p>
          We are working on fixing the problem. <br /> Please try again.
        </p>
        <div style={{ marginTop: '15px' }}>
          <Button
            variant={'contained'}
            color="primary"
            className={`${classes.errorButton}`}
            onClick={() => showReportDialog({ eventId: this.state.eventId })}
          >
            Feedback Report
          </Button>
          <Button
            variant={'contained'}
            className={`${classes.errorButton} ${classes.btnReload}`}
            onClick={() => window.location.reload()}
          >
            Reload the Page
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(customStyles)(ErrorBoundary);
