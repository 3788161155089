import { TextField } from '@mui/material';
import { useAddressAutocomplete } from 'actions/helpers';
import React from 'react';

export function AddressAutocomplete({
  label,
  name,
  onChange,
  onAutocompleteChange,
  classes,
  fieldData,
  description,
}) {
  const { ref, enabled } = useAddressAutocomplete(onAutocompleteChange);

  return (
    <TextField
      fullWidth
      inputRef={enabled ? ref : undefined}
      name={name}
      onBlur={onChange}
      onChange={onChange}
      label={label}
      variant="filled"
      value={fieldData.street}
      className={classes.field}
      helperText={description}
      inputProps={{
        'data-id': 'lighthouse-street',
      }}
    />
  );
}
