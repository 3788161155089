import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../constants/routes';
import BackButton from '../../Common/BackButton';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { styles } from '../../../styles';
import { vh } from '../../../styles/helper';
import PersonalInformationForm from './PersonalInformationForm';
import { helpers } from '../../../actions';

const PersonalInformation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticTexts = useSelector((state) => state.textsState.data);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [storedDateOfBirth, setStoredDateOfBirth] = useState('');
  const [fieldsError, setFieldsError] = useState({});
  const [dateError, setDateError] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [other, setOther] = useState('');

  const [disableNextStep, setDisableNextStep] = useState(false);

  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('30px'),
        paddingBottom: vh('30px'),
        minHeight: vh('782px'),
        height: 'auto',
      },
    },
  }));
  const classes = useStyles();

  // On change first name.
  const onChangeFirstName = (event) => {
    return setFirstName(event.target.value.substring(0, 60));
  };

  // On change last name.
  const onChangeLastName = (event) => {
    return setLastName(event.target.value.substring(0, 60));
  };

  // On change gender.
  const onChangeGender = (event) => {
    setGender(event.target.value);
  };

  // On change other.
  const onChangeOther = (event) => {
    return setOther(event.target.value.substring(0, 60));
  };

  // Input field validation.
  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = '@field is required'.replace('@field', fieldName);

    if (customValidator === 'firstname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateFirstName(e.target.value);
      }
    } else if (customValidator === 'lastname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateLastName(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  // Validate first name.
  const doValidateFirstName = (firstname) => {
    if (helpers.isValidName(firstname)) {
      setFieldsError({
        ...fieldsError,
        firstname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        firstname: staticTexts.FirstNameVerificationNotValid,
      });
    }
  };

  // Validate last name.
  const doValidateLastName = (lastname) => {
    if (helpers.isValidName(lastname)) {
      setFieldsError({
        ...fieldsError,
        lastname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        lastname: staticTexts.LastNameVerificationNotValid,
      });
    }
  };

  // Form validation: activate submit button if everything is filled correctly.
  const checkValidForm = () => {
    if (
      ((typeof fieldsError.firstname === 'undefined' &&
        firstName !== '' &&
        helpers.isValidName(firstName)) ||
        fieldsError.firstname === false) &&
      ((typeof fieldsError.lastname === 'undefined' &&
        lastName !== '' &&
        helpers.isValidName(lastName)) ||
        fieldsError.lastname === false) &&
      storedDateOfBirth &&
      !dateError
    ) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  };

  // Confirm personal information.
  const confirm = () => {
    let accountUpdates = {
      firstname: firstName,
      lastname: lastName,
      flags: {
        personalInformation: true,
      },
    };

    if (gender) {
      accountUpdates = {
        ...accountUpdates,
        gender: gender,
      };
    }

    if (storedDateOfBirth) {
      accountUpdates = {
        ...accountUpdates,
        dateOfBirth: storedDateOfBirth,
      };
    }

    setDisableNextStep(true);
    helpers
      .addAccountInfo(accountUpdates, authUser.uid)
      .then(() => {
        history.push(routes.AFFINITY);
      })
      .catch((error) => {
        setDisableNextStep(false);
        helpers.handleError(error, dispatch);
      });
  };

  // Open other item.
  const openOther = () => {
    setOpen(true);
  };

  // Close other item.
  const closeOther = () => {
    setOpen(false);
  };

  // Set fields if defined.
  useEffect(() => {
    if (accountData) {
      if (typeof accountData.firstname !== 'undefined') {
        setFirstName(accountData.firstname);
      }
      if (typeof accountData.lastname !== 'undefined') {
        setLastName(accountData.lastname);
      }
      if (typeof accountData.gender !== 'undefined') {
        setGender(accountData.gender);
      }
      if (accountData.dateOfBirth) {
        //  Check type of field and fill it.
        if (typeof accountData.dateOfBirth === 'string') {
          setStoredDateOfBirth(accountData.dateOfBirth);
        }
      }
    }
  }, [accountData]);

  // Form validation for activating submit button.
  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError, firstName, lastName, storedDateOfBirth, dateError])

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      <>
        <BackButton disabled={disableNextStep} />
        <PersonalInformationForm
          firstname={firstName}
          onChangeFirstName={onChangeFirstName}
          lastname={lastName}
          onChangeLastName={onChangeLastName}
          gender={gender}
          onChangeGender={onChangeGender}
          storedDateOfBirth={storedDateOfBirth}
          setStoredDateOfBirth={setStoredDateOfBirth}
          dateError={dateError}
          setDateError={setDateError}
          fieldsError={fieldsError}
          inputValidation={inputValidation}
          staticTexts={staticTexts}
          isValidForm={isValidForm}
          confirm={confirm}
          open={open}
          openOther={openOther}
          closeOther={closeOther}
          other={other}
          onChangeOther={onChangeOther}
          disabled={disableNextStep}
        />
      </>
    </Box>
  );
};

export default PersonalInformation;
