import React from 'react';
import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../styles';
import { vh } from '../../../styles/helper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  header: {
    ...styles.globalRewritesExport,
    position: 'relative',
    height: vh('72px'),
    background: '#F8F9FA',
    marginBottom: vh('18px'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: vh('16px'),
    flexWrap: 'nowrap',
    flexDirection: 'row',
    borderBottomWidth: vh('1px'),
    borderBottomStyle: 'solid',
    borderBottomColor: '#F8F9FA',
    [theme.breakpoints.up('md')]: {
      marginBottom: vh('50px'),
      height: vh('91px'),
      padding: vh('19px') + ' ' + vh('60px'),
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: vh('72px'),
    },
  },
  logo: {
    display: 'flex',
    '& img': {
      width: vh('75px'),
    },
  },
  partnerLogoWrapper: {
    position: 'relative',
    height: vh('29px'),
    borderLeft: '1px solid #B3BBCA',
    marginLeft: vh('10px'),
    padding: vh('2px') + ' 0 ' + vh('2px') + ' ' + vh('10px'),
    [theme.breakpoints.up('md')]: {
      marginLeft: vh('20px'),
      padding: vh('4px') + ' 0 ' + vh('4px') + ' ' + vh('20px'),
      height: vh('47px'),
    },
  },
  partnerLogo: {
    maxHeight: '100%',
  },
  container: {
    padding: vh('15px'),
  },
  marginHelper: {
    marginTop: vh('5px'),
    marginBottom: vh('11px'),
  },
  field: {
    borderBottom: '0 none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    '&:before': {
      display: 'none',
    },
  },
  button: {
    borderRadius: '0',
    paddingTop: vh('14px'),
    paddingBottom: vh('14px'),
  },
  closeWrapper: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.up('md')]: {
      right: vh('60px'),
    },
  },
  close: {
    color: '#001E33',
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const iframe = useSelector(
    (state) => state.sessionDBState.data.urlQuery.iframe
  );

  return (
    <AppBar position="static" className={classes.header} elevation={0}>
      <div className={classes.logo}>
        <img src={'/assets/images/we-salute-logo.svg'} alt={'WeSalute'} />
      </div>
      {props.brandData?.logo && (
        <div className={classes.partnerLogoWrapper}>
          <img
            src={props.brandData.logo}
            alt={props.brandData.label}
            className={classes.partnerLogo}
          />
        </div>
      )}
      {props.showClose && !iframe && (
        <div className={classes.closeWrapper}>
          <IconButton
            aria-label="back"
            className={classes.close}
            onClick={props.close}
            data-id="close-btn"
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
      )}
    </AppBar>
  );
};

export default Header;
