import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styles } from '../../../../styles';

const styleOverride = {};

const ActiveDutyCodeForm = (props) => {
  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const {
    onSubmitCode,
    onSkip,
    error,
    resendCode,
    code,
    onChangeInput,
    inputValidation,
    staticText,
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h1" component="h1">
        {staticText.ActiveDutyCodeFormTitle}
      </Typography>
      <Typography variant="h2" component="h2">
        {staticText.ActiveDutyCodeFormSubTitle}
      </Typography>

      <div className={classes.centerContent + ' ' + classes.alignContentCenter}>
        <div className={'form-item'}>
          <label htmlFor="code">{staticText.ActiveDutyCodeFormCodeLabel}</label>
          <FormControl error={!!error} variant="filled" fullWidth>
            <InputLabel htmlFor="code">
              {staticText.ActiveDutyCodeFormCodePlaceolder}
            </InputLabel>
            <FilledInput
              id="code"
              className={classes.field}
              value={code}
              inputProps={{
                'data-id': 'active-duty-code',
              }}
              onChange={(e) => onChangeInput(e)}
              onBlur={(e) => {
                inputValidation(
                  e,
                  staticText.ActiveDutyCodeFormCodePlaceolderVerify,
                  true
                );
              }}
            />
            <FormHelperText className={classes.marginHelper}>
              {error ? error.message : ''}
            </FormHelperText>
          </FormControl>
        </div>

        <Button
          onClick={onSubmitCode}
          className={classes.button}
          variant={error ? 'outlined' : 'contained'}
          disabled={!!error || code === ''}
          color="primary"
          disableElevation
          fullWidth
          size="large"
          data-id="active-duty-code-btn"
        >
          {staticText.ActiveDutyCodeFormBtn}
        </Button>
        <Button
          onClick={onSkip}
          className={classes.buttonOutlined}
          variant="contained"
          color="secondary"
          disableElevation
          fullWidth
          size="large"
          data-id="active-duty-skip"
        >
          {staticText.ActiveDutyCodeFormSkipBtn}
        </Button>
        <Button
          onClick={resendCode}
          className={classes.buttonClean}
          color="primary"
          disableElevation
          fullWidth
          size="large"
          data-id="active-duty-resend"
        >
          {staticText.ActiveDutyCodeFormResendBtn}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ActiveDutyCodeForm;
