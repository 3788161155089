import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { authHelper, recaptcha } from '../../../firebase/firebase';

import Preloader from '../../Preloader';
import PhoneForm from './PhoneForm';
import PhoneCode from './PhoneCode';
import * as routes from '../../../constants/routes';

import { analytics, db, getCustomRoute, helpers } from '../../../actions';
import {
  getAllIdentificationStatuses,
  isFullyVerified,
} from '../../../actions/getCustomRoute';
import { getAuth } from 'firebase/auth';

const PhoneVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);
  const allowTests = useSelector((state) => {
    if (
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'stage'
    ) {
      return Boolean(state.sessionDBState.data.urlQuery.allowTests);
    }
    return false;
  });

  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [applicationVerifier, setApplicationVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [codeVisible, setCodeVisible] = useState(false);
  const [inlineLoading, setInlineLoading] = useState(false);
  const [code, setCode] = useState('');
  const [resend, setResend] = useState(false);
  const [migratePhone, setMigratePhone] = useState(false);
  const [finish, setFinish] = useState(false);

  const { setErrorPage } = db;

  // Initiate component.
  useEffect(() => {
    if (
      authUser &&
      accountData &&
      typeof accountData.memberId !== 'undefined' &&
      accountData.memberId &&
      accountData.memberId !== '' &&
      typeof accountData.roles !== 'undefined' &&
      typeof accountData.roles[0] !== 'undefined' &&
      accountData.roles[0] !== ''
    ) {
      // Initiate component if email verification is not enabled.
      initiateComponent();
    }
  }, [finish]);

  useEffect(() => {
    if (finish) {
      setLoading(true);
    }
  }, [finish]);

  /**
   * Redirect user once Prove passed or failed
   * or Identity verification passed.
   */
  useEffect(() => {
    const { proveStatus } = getAllIdentificationStatuses(accountData);
    const verified = isFullyVerified(accountData);
    if (verified || ['passed', 'failed'].indexOf(proveStatus) !== -1) {
      getCustomRoute(history);
    }
  }, [accountData]);

  const initiateComponent = () => {
    // Check if there is already phone and populate it.
    if (
      typeof accountData.phone !== 'undefined' &&
      accountData.phone &&
      String.prototype.trim.call(accountData.phone) !== '' &&
      !migratePhone
    ) {
      setMigratePhone(true);

      const regForPlusSign = /\+/;

      // Check if phone have + sign and if it does not add US country code.
      if (regForPlusSign.test(accountData.phone)) {
        setPhone(accountData.phone);
      } else {
        setPhone('+1' + accountData.phone.replace(/\D/g, ''));
      }
    }
    setLoading(false);
  };

  const loadCaptcha = (redo) => {
    // Allow tests
    if (allowTests) {
      getAuth().settings.appVerificationDisabledForTesting = true;
    }
    if (!applicationVerifier || redo) {
      window.recaptchaVerifier = recaptcha('phone-verification-button', {
        size: 'invisible',
        callback: () => {},
        'expired-callback': () => {
          dispatch(
            setErrorPage(
              staticTexts.PhoneVerificationRecaptchaExpiredTitle,
              staticTexts.PhoneVerificationRecaptchaExpiredDescription,
              'error',
              false,
              staticTexts.PhoneVerificationRecaptchaExpiredBtn,
              routes.SIGN
            )
          );
        },
      });
      setApplicationVerifier(window.recaptchaVerifier);
    }
  };

  const onPhoneLoginSubmit = () => {
    setInlineLoading(true);
    setError(null);

    const provider = new authHelper.PhoneAuthProvider(authHelper.auth);
    return provider
      .verifyPhoneNumber(phone, applicationVerifier)
      .then((verificationId) => {
        analytics.track('Phone Verification Started', {
          phone: phone,
        });
        setVerificationId(verificationId);
        setCodeVisible(true);
        setInlineLoading(false);
        // Reset phone number to trigger then Prove trustScore re-calculation.
        return helpers.addAccountInfo(
          {
            phone: '',
          },
          authUser.uid
        );
      })
      .catch((error) => {
        console.log('error', error);
        if (error.code === 'auth/invalid-phone-number') {
          console.log(error);
        } else {
          Sentry.captureException(error);
          console.error(error);
        }

        setLoading(false);
        if (error.message === 'TOO_SHORT') {
          error.message = staticTexts.EmailErrorTooShort;
        }
        setError(error);
        setInlineLoading(false);
      });
  };

  const onPhoneNumberChange = (value) => {
    // Set the phone, and escape all but numbers and +.
    setPhone('+' + value.replace(/[^0-9+]+/g, ''));
  };

  const onCodeSubmit = () => {
    setInlineLoading(true);

    const phoneCredential = authHelper.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    return authHelper
      .updatePhoneNumber(authHelper.auth.currentUser, phoneCredential)
      .then(() => {
        analytics.track('Phone Verification Verified', {
          phone: phone,
        });
        return helpers.addAccountInfo(
          {
            phone: phone,
            phoneVerified: true,
          },
          authUser.uid
        );
      })
      .then(() => {
        setFinish(true);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(
          'This credential is already associated with a different user account.',
          error
        );
        setError(error);
        setCodeVisible(false);
        setInlineLoading(false);

        if (
          error.message ===
          'This credential is already associated with a different user account.'
        ) {
          dispatch(
            setErrorPage(
              staticTexts.PhoneVerificationCodeErrorAlreadyLinkedTitle,
              staticTexts.PhoneVerificationCodeErrorAlreadyLinked,
              'warning',
              false,
              staticTexts.PhoneVerificationCodeErrorSignInLabel,
              routes.SIGN,
              false,
              false,
              true
            )
          );
        }

        setApplicationVerifier(null);
        loadCaptcha(true);
      });
  };

  const onCodeChange = (event) => {
    setCode(event.target.value.replace(/ /g, ''));
  };

  const goBackCode = (event) => {
    event.preventDefault();

    setCodeVisible(false);
    setResend(true);
    setApplicationVerifier(null);
    loadCaptcha(true);
  };

  const goBack = (event) => {
    event.preventDefault();

    setCodeVisible(false);
    setApplicationVerifier(null);
    loadCaptcha(true);
  };

  const description = staticTexts?.PhoneVerificationCodeDescription?.replace(
    '@phone',
    phone
  );

  if (loading) {
    return <Preloader title={staticTexts?.PhoneVerificationLoading} />;
  } else if (!codeVisible) {
    const isInvalid =
      !phone || phone.length < 6 || phone === '' || inlineLoading;

    return (
      <PhoneForm
        phone={phone}
        error={error}
        isInvalid={isInvalid}
        onPhoneNumberChange={onPhoneNumberChange}
        onPhoneLoginSubmit={onPhoneLoginSubmit}
        loadCaptcha={loadCaptcha}
        inlineLoading={inlineLoading}
      />
    );
  } else {
    const isInvalid = code.length !== 6 || inlineLoading;

    return (
      <PhoneCode
        onCodeSubmit={onCodeSubmit}
        code={code}
        resend={resend}
        onCodeChange={onCodeChange}
        goBackCode={goBackCode}
        goBack={goBack}
        isInvalid={isInvalid}
        description={description}
        inlineLoading={inlineLoading}
      />
    );
  }
};

export default PhoneVerification;
