const ratio = window.innerWidth / window.innerHeight;
export const vh = (unit) => {
  if (ratio < 0.36) {
    return parseFloat(unit) / 14 + 'vh';
  } else if (ratio < 0.42) {
    return parseFloat(unit) / 9.1188 + 'vh';
  } else if (ratio < 0.57) {
    return parseFloat(unit) / 8.1188 + 'vh';
  } else {
    return parseFloat(unit) / 10.24 + 'vh';
  }
};
