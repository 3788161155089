import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../../../styles';
import { handleError, saveIdentification } from '../../../../actions/helpers';
import getCustomRoute, {
  getAllIdentificationStatuses,
} from '../../../../actions/getCustomRoute';
import { useHistory } from 'react-router-dom';
import { fetchAccountInfo } from '../../../../actions/db';
import Preloader from '../../../Preloader';
import { vh } from '../../../../styles/helper';

const styleOverride = {
  pageSubTitle: {
    marginTop: vh('13px'),
    marginBottom: vh('40px'),
  },
  footer: {
    marginBottom: 'auto',
  },
};

function AtomicFailureScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    ...styles.defaultFormStyles,
    ...styleOverride,
  }));

  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticText = useSelector((state) => state.textsState.data);

  const atomicFinancialFailureTitle =
    staticText?.AtomicFinancialFailureTitle ?? 'Something went wrong';
  const atomicFinancialFailureSubtitle =
    staticText?.AtomicFinancialFailureSubtitle ??
    'We were unable to process your request.';
  const atomicFinancialFailureVerifyManuallyOption =
    staticText?.AtomicFinancialVerifyManuallyOption ?? 'Verify Another Way';

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // Wait for Atomic Financial Reset.
  // TODO uncomment when get to this part.
  useEffect(() => {
    const { atomicStatus } = getAllIdentificationStatuses(accountData);
    // Check if status was set to failed and skip button pressed.
    if (atomicStatus === 'failed' && loading) {
      // Send to Atomic Financial another trial.
      getCustomRoute(history);
    }
  }, [accountData?.identification, loading]);

  const skip = async () => {
    setLoading(true);
    await saveIdentification({ type: 'atomicfi', status: 'failed' }).catch(
      (error) => {
        handleError(error, dispatch);
      }
    );
    dispatch(fetchAccountInfo(authUser.uid));
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      {loading && (
        <Preloader errorTitle="Error loading Atomic Financial Failure screen" />
      )}
      <Typography variant="h1" component="h1">
        {atomicFinancialFailureTitle}
      </Typography>
      <Typography variant="h2" component="h2" className={classes.pageSubTitle}>
        {atomicFinancialFailureSubtitle}
      </Typography>
      <div className={classes.footer}>
        <Button
          className={classes.buttonOutlined}
          disableElevation
          onClick={skip}
          fullWidth
          size="large"
          data-id="atomic-financial-failure-skip"
        >
          {atomicFinancialFailureVerifyManuallyOption}
        </Button>
      </div>
    </Box>
  );
}

export default AtomicFailureScreen;
