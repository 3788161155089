import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ActiveCredentials = (props) => {
  const { branch, branches, handleBranchChange, staticText } = props;

  return (
    <React.Fragment>
      <div className="form-item-select">
        <label>{staticText.ActiveCredentialsBranchLabel}</label>
        <Autocomplete
          value={branch}
          options={branches}
          getOptionLabel={(option) => option.label}
          onChange={handleBranchChange}
          ListboxProps={{ 'data-id': 'active-credentials-branch-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.ActiveCredentialsBranchPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'active-credentials-branch',
              }}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default ActiveCredentials;
