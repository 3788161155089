import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { db, helpers } from '../../../actions';
import Preloader from '../../Preloader';
import * as routes from '../../../constants/routes';
import QuestionContainer from './Question';
import BackButton from '../../Common/BackButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { vh } from '../../../styles/helper';
import { styles } from '../../../styles';
import * as Sentry from '@sentry/browser';
import getCustomRoute, {
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { fetchAccountInfo } from '../../../actions/db';

const Quiz = () => {
  const { chooseAnswers, fetchQuiz, updateSession, setErrorPage } = db;

  const history = useHistory();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const quizData = useSelector((state) => state.quizState.quiz);
  const quizDataStatus = useSelector((state) => state.quizState.status);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const staticText = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('50px'),
        paddingBottom: vh('20px'),
      },
    },
  }));
  const classes = useStyles();

  const [answers, setAnswers] = useState([]);
  const [active, setActive] = useState(1);
  const [skips, setSkips] = useState(0);
  const [loading, setLoading] = useState(true);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizFetched, setQuizFetched] = useState(false);

  useEffect(() => {
    if (!authUser) {
      history.push(routes.SIGN);
    } else if (accountData) {
      // Check if branch exist.
      if (accountData.branch) {
        // Load quiz only once.
        if (!quizFetched) {
          setQuizFetched(true);
          dispatch(fetchQuiz(accountData.branch));
        }
      }
    }
    // eslint-disable-next-line
  }, [accountData])

  // Wait for quiz verify.
  useEffect(() => {
    if (quizSubmitted) {
      const { quizStatus } = getAllIdentificationStatuses(accountData);
      // Check if status was set.
      if (['passed', 'failed'].indexOf(quizStatus) !== -1) {
        getCustomRoute(history);
      }
    }
  }, [accountData, quizSubmitted]);

  // On answers submit.
  useEffect(() => {
    if (
      answers.length === 4 ||
      (sessionDB.data.affinity === 'academy' && answers.length === 3)
    ) {
      dispatch(chooseAnswers(answers));

      dispatch(
        updateSession(
          {
            last_update: new Date(),
            quiz: answers,
          },
          sessionDB.id
        )
      );

      // Check if branch exist.
      if (sessionDB.data.branch || accountData?.branch) {
        // Write session info.
        helpers
          .writeSession(
            {
              quiz: answers ? answers : [],
            },
            sessionDB.id
          )
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });

        helpers
          .doVerifyQuiz(
            {
              user_id: authUser.uid,
              quiz: answers ? answers : [],
              rank: accountData.rank ? accountData.rank : '',
              grade: accountData.paygrade ? accountData.paygrade : '',
              branch: sessionDB.data.branch ?? accountData?.branch,
              skip: sessionDB.data.affinity === 'academy',
            },
            sessionDB.id,
            authUser.uid
          )
          .then(() => {
            // Load latest accountData with updated affinity.
            dispatch(fetchAccountInfo(authUser.uid));
            return true;
          })
          .catch((error) => {
            setQuizSubmitted(false);
            helpers.handleError(error, dispatch);
            history.push(routes.SIGN);
          });
        setQuizSubmitted(true);
      } else {
        dispatch(
          setErrorPage(
            "Oops! There seems to be a problem, don't hesitate to contact us."
          )
        );
        const error = new Error('Problem with branch data in local session.');
        Sentry.captureException(error);
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [active])

  // Preloader for Quiz data.
  useEffect(() => {
    if (
      quizDataStatus === 'loading' ||
      quizData.length < 3 ||
      (sessionDB.data.affinity !== 'academy' && quizData.length < 6)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [quizDataStatus, quizData, sessionDB]);

  const chooseAnswer = (questionID, answer) => {
    setAnswers([
      ...answers,
      { question_id: questionID, answer_id: answer.value },
    ]);
    setActive(active + 1);
  };

  const skipAnswer = () => {
    setSkips(skips + 1);
    setActive(active + 1);
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      {loading && (
        <Preloader
          title={staticText.QuizLoader}
          errorTitle="Error loading Quiz page"
        />
      )}
      {quizSubmitted && (
        <React.Fragment>
          <Typography variant="h1" component="h1" className={classes.onTop}>
            {staticText.verifyLoading}
          </Typography>
          <Preloader
            waitFor={60000}
            errorTitle="Error loading Quiz Submitted page"
          />
        </React.Fragment>
      )}
      {!quizSubmitted && (
        <>
          <BackButton />
          <Typography variant="h1" component="h1">
            {staticText.QuizTitle}
          </Typography>
          <Typography variant="h2" component="h2">
            {staticText.QuizSubTitle}
          </Typography>

          {Object.keys(quizData).map((key, index) => {
            const id = quizData[key].id;
            const quiz = quizData[key];
            const question = quiz.question;
            const options = quiz.answers;

            if (active === index + 1) {
              return (
                <QuestionContainer
                  key={index}
                  activeQuestion={active - skips}
                  total={sessionDB.data.affinity === 'academy' ? 3 : 4}
                  id={id}
                  question={question}
                  options={options}
                  onClick={chooseAnswer}
                  onSkip={skipAnswer}
                  skips={sessionDB.data.affinity === 'academy' ? 4 : skips}
                  staticText={staticText}
                />
              );
            } else {
              return '';
            }
          })}
        </>
      )}
    </Box>
  );
};

export default Quiz;
