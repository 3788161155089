import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { analytics } from '../../../../actions';
import Preloader from '../../../Preloader';
import { styles } from '../../../../styles';
import { vh } from 'styles/helper';

const styleOverride = {
  resendPhoneLink: {
    display: 'block',
    textAlign: 'center',
    color: '#001E33',
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
    },
    fontSize: vh('14px'),
    lineHeight: vh('15px'),
    marginBottom: vh('10px'),
  },
  phoneMoreText: {
    display: 'block',
    textAlign: 'center',
    fontSize: vh('14px'),
    lineHeight: vh('20px'),
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  form: {
    ...styles.defaultFormStyles.form,
    marginBottom: vh('180px'),
  },
  pageTitle: {
    ...styles.defaultFormStyles.pageTitle,
    marginBottom: vh('20px'),
  },
  button: {
    ...styles.defaultFormStyles.button,
    paddingTop: vh('14px'),
    paddingBottom: vh('14px'),
    marginTop: vh('25px'),
    marginBottom: vh('25px'),
  },
};

const PhoneCode = (props) => {
  const staticTexts = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles(() => ({
    ...styles.defaultFormStyles,
    ...styles.backButtonStyles,
    ...styleOverride,
  }));
  const classes = useStyles();

  useEffect(() => {
    analytics.page('Phone Code Verification Form');
    // eslint-disable-next-line
  }, [])

  const {
    onCodeSubmit,
    code,
    onCodeChange,
    isInvalid,
    description,
    goBackCode,
    goBack,
    inlineLoading,
  } = props;

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <IconButton
        aria-label="back"
        className={classes.back}
        onClick={goBack}
        data-id="back-btn"
      >
        <ArrowBackIosIcon fontSize="medium" />
      </IconButton>
      <div>
        <Typography variant="h1" component="h1" className={classes.pageTitle}>
          {staticTexts.PhoneVerificationCodeTitle}
        </Typography>

        <Typography
          variant="h2"
          component="h2"
          className={classes.pageSubTitle}
        >
          {description}
        </Typography>
      </div>

      <form className={classes.form} noValidate autoComplete="off">
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="code">
            {staticTexts.PhoneVerificationCodeLabel}
          </InputLabel>
          <FilledInput
            id="code"
            className={classes.field}
            value={code}
            onChange={onCodeChange}
            data-id="phone-code"
          />
        </FormControl>

        {inlineLoading && (
          <Preloader title="Verifying your request." waitFor={90000} />
        )}

        <Button
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          type="button"
          color="primary"
          onClick={onCodeSubmit}
          disableElevation
          fullWidth
          data-id="phone-verification-button"
          size="large"
        >
          {staticTexts.PhoneVerificationCodeButton}
        </Button>

        <div className={classes.resendPhoneLink}>
          <Link href={'#'} onClick={goBackCode} className={classes.link}>
            {staticTexts.PhoneVerificationCodeResendCode}
          </Link>
        </div>

        {props.resend && (
          <Typography
            variant="caption"
            component="div"
            className={classes.phoneMoreText}
          >
            {staticTexts.PhoneVerificationCodeResendCodeAlert}
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default PhoneCode;
