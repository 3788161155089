import React, { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { styles } from '../../../../styles';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const styleOverride = {};

const RelativeInfo = (props) => {
  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const [error, setError] = useState(false);

  const {
    firstname,
    onChangeFirstName,
    lastname,
    relativeDateOfBirth,
    handleRelativeDateChange,
    onChangeLastName,
    fieldsError,
    inputValidation,
    staticText,
  } = props;

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'You need to be at least 13 years old';
      }
      case 'minDate': {
        return 'Please select from 01/01/1900';
      }

      case 'invalidDate': {
        return 'Please follow MM/DD/YYYY format';
      }

      default: {
        return '';
      }
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className="form-item">
        <label htmlFor="firstname">
          {staticText.RelativeInfoFirstNameLabel}
        </label>
        <FormControl error={!!fieldsError.firstname} variant="filled" fullWidth>
          <InputLabel htmlFor="firstname">
            {staticText.RelativeInfoFirstNamePlaceholder}
          </InputLabel>
          <FilledInput
            id="firstname"
            className={classes.field}
            value={firstname}
            inputProps={{
              'data-id': 'relative-info-firstname',
            }}
            onChange={(e) => onChangeFirstName(e)}
            onBlur={(e) => {
              inputValidation(
                e,
                staticText.RelativeInfoFirstNamePlaceholder,
                true
              );
            }}
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.firstname}
          </FormHelperText>
        </FormControl>
      </div>
      <div className="form-item">
        <label htmlFor="lastname">{staticText.RelativeInfoLastNameLabel}</label>
        <FormControl error={!!fieldsError.lastname} variant="filled" fullWidth>
          <InputLabel htmlFor="lastname">
            {staticText.RelativeInfoLastNamePlaceholder}
          </InputLabel>
          <FilledInput
            id="lastname"
            className={classes.field}
            value={lastname}
            inputProps={{
              'data-id': 'relative-info-lastname',
            }}
            onChange={(e) => onChangeLastName(e)}
            onBlur={(e) => {
              inputValidation(
                e,
                staticText.RelativeInfoLastNamePlaceholder,
                true
              );
            }}
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.lastname}
          </FormHelperText>
        </FormControl>
      </div>
      <div className="form-item">
        <label htmlFor="dateOfBirth">
          {staticText.RelativeInfoDateOfBirthLabel}
        </label>
        <DateField
          className={classes.dateField}
          inputProps={{
            'data-id': 'date-of-birth',
          }}
          label="Date of birth"
          format="MM/DD/YYYY"
          value={relativeDateOfBirth}
          onError={(newError) => setError(newError)}
          onChange={handleRelativeDateChange}
          minDate={dayjs('1900-01-01', 'YYYY-MM-DD')}
          maxDate={dayjs().subtract(13, 'y')}
          slotProps={{
            textField: {
              variant: 'filled',
              helperText: errorMessage ? errorMessage : '',
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default RelativeInfo;
