import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../styles';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../Common/BackButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import {
  getAffinity,
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { relation } from '../../../constants/affinityAndSubgroup';

const styleOverride = {
  affinity: {
    '& span.MuiTypography-body1': {
      fontWeight: '400',
    },
  },
};

const Affinity = () => {
  // Get needed Redux values.
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticText = useSelector((state) => state.textsState.data);

  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  // Const affinity array.
  const affinityTypes = [
    {
      key: 'veteran',
      text: 'AffinityVeteran',
    },
    {
      key: 'active_duty',
      text: 'AffinityActive',
    },
    {
      key: 'academy',
      text: 'AffinityAcademy',
    },
    {
      key: 'commissioned',
      text: 'AffinityCommissioned',
    },
    {
      key: 'relative',
      text: 'AffinityRelative',
    },
  ];

  const existingAffinity = getAffinity(accountData);
  const affinityChangeWarning = staticText.AffinityChangeWarning
    ? staticText.AffinityChangeWarning
    : 'May require additional round of verification.';

  // Prepare state variables.
  const [affinity, setAffinity] = useState(existingAffinity);
  const [message, setMessage] = useState('');
  const [disableNextStep, setDisableNextStep] = useState(false);

  /**
   * Change affinity helper.
   *
   * @param event
   */
  const changeAffinity = (event) => {
    const { militaryStatus, identityStatus } =
      getAllIdentificationStatuses(accountData);
    // If affinity already exists and user is verified,
    // then we show new verification warning when it is changed.
    if (
      existingAffinity &&
      existingAffinity !== event.target.value &&
      militaryStatus === 'verified' &&
      identityStatus === 'verified'
    ) {
      setMessage(affinityChangeWarning);
    } else {
      setMessage('');
    }
    setAffinity(event.target.value);
  };

  /**
   * Next step helper.
   */
  const nextStep = () => {
    setDisableNextStep(true);
    helpers
      .setAffinityAndSubgroup(relation[affinity])
      .then(() => {
        // Direct user to the next verification step.
        history.push(routes.AFFIDAVIT);
      })
      .catch((error) => {
        setDisableNextStep(false);
        helpers.handleError(error, dispatch);
      });
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <BackButton disabled={disableNextStep} />

      <div className={'header'}>
        <Typography variant="h1" component="h1">
          {staticText.MilitaryCredentialsTitle}
        </Typography>
        <Typography variant="h2" component="h2">
          {staticText.MilitaryCredentialsSubTitle}
        </Typography>
      </div>

      <div className={classes.centerContent}>
        <FormControl component="fieldset" className={classes.affinity}>
          {message && (
            <FormHelperText error={true} className={classes.marginHelper}>
              {message}
            </FormHelperText>
          )}
          <RadioGroup
            aria-label="affinity"
            name="affinity"
            defaultValue={affinity}
            value={affinity}
            onChange={changeAffinity}
          >
            {authUser &&
              affinityTypes.map((affinityType) => (
                <FormControlLabel
                  key={affinityType.key}
                  control={
                    <Radio
                      color="secondary"
                      inputProps={{
                        'data-id':
                          'military-credentials-affinity-' +
                          affinityType.key.replace('_', '-'),
                      }}
                    />
                  }
                  checked={affinity === affinityType.key}
                  editable={'true'}
                  value={affinityType.key}
                  label={staticText[affinityType.text]}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className={'footer'}>
        <Button
          className={classes.button}
          type="submit"
          disableElevation
          onClick={nextStep}
          disabled={!affinity || disableNextStep}
          fullWidth
          size="large"
          data-id="military-credentials-next"
        >
          {staticText?.MilitaryCredentialsBtn
            ? staticText?.MilitaryCredentialsBtn
            : 'Next Step'}
        </Button>
      </div>
    </Box>
  );
};

export default Affinity;
