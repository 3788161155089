import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../../styles';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const styleOverride = {};

const VeteranCredentials = (props) => {
  const {
    branch,
    branches,
    timeServed,
    timesServed,
    handleBranchChange,
    handleTimeServedChange,
    dischargeDate,
    handleDischargeDateChange,
    staticText,
  } = props;

  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className="form-item-select">
        <label>{staticText.VeteranCredentialsBranchLabel}</label>
        <Autocomplete
          value={branch}
          options={branches}
          getOptionLabel={(option) => option.label}
          onChange={handleBranchChange}
          ListboxProps={{ 'data-id': 'military-credentials-branch-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.VeteranCredentialsBranchPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'military-credentials-branch',
              }}
            />
          )}
        />
      </div>
      <div className="form-item-select">
        <label>{staticText.VeteranCredentialsEraLabel}</label>
        <Autocomplete
          value={timeServed}
          options={timesServed}
          getOptionLabel={(option) => option.label}
          onChange={handleTimeServedChange}
          ListboxProps={{ 'data-id': 'military-credentials-era-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.VeteranCredentialsEraPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'military-credentials-era',
              }}
            />
          )}
        />
      </div>
      <div className={'form-item-datepicker'}>
        <div className={'form-item ' + classes.dateOfBirth}>
          <label htmlFor="dateOfBirth">
            {staticText.VeteranCredentialsDischargeLabel}
          </label>
          <DateField
            className={classes.dateField}
            inputProps={{
              'data-id': 'discharge-date',
            }}
            label="Discharge Date"
            format="MM/DD/YYYY"
            value={dischargeDate}
            onChange={handleDischargeDateChange}
            slotProps={{
              textField: {
                variant: 'filled',
              },
            }}
            minDate={dayjs('1920-01-01', 'YYYY-MM-DD')}
            maxDate={dayjs()}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VeteranCredentials;
