import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

/**
 * Helper hook to handle Segment page event processing.
 */
export const useSegmentPageTracking = () => {
  let location = useLocation();

  useEffect(() => {
    if (!window.analytics) return;
    const pageName = eventNameBeautifier(location.pathname);
    if (pageName) {
      window.analytics.page(pageName);
    }
  }, [location]);

  const eventNameBeautifier = (path) => {
    try {
      const sanitizedPath = path.replace(/[^a-zA-Z0-9]+/g, ' ').trim();
      return sanitizedPath.charAt(0).toUpperCase() + sanitizedPath.slice(1);
    } catch (error) {
      console.error('Error processing page path', error);
      Sentry.captureException(error);
    }
  };
};
