import React from 'react';
import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { styles } from '../../../../styles';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { vh } from '../../../../styles/helper';
import Typography from '@mui/material/Typography';
import CustomDateInput from '../CustomDateInput';

const PersonalInformationForm = (props) => {
  const useStyles = makeStyles(() => ({
    ...styles.defaultFormStyles,
    pageTitle: {
      lineHeight: vh('40px'),
      marginBottom: vh('18px'),
    },
    pageSubTitle: {
      backgroundColor: 'rgba(255,196,62,0.4)',
      padding: vh('10px'),
      fontSize: vh('14px'),
      lineHeight: vh('21px'),
    },
    pageContent: {
      paddingTop: vh('20px'),
    },
    genderControl: {
      display: 'block',
      marginTop: vh('-5px'),
      '& .MuiFormControlLabel-root': {
        marginLeft: vh('-5px'),
      },
      '& .MuiIconButton-root': {
        padding: vh('5px'),
        marginRight: vh('6px'),
      },
      // Fix for V5.
      '& .MuiRadio-root': {
        padding: vh('5px'),
        marginRight: vh('6px'),
      },
    },
    dateOfBirth: {
      marginTop: vh('16px'),
    },
    buttonLast: {
      marginTop: vh('20px'),
    },
    buttonOther: {
      display: 'block',
      textAlign: 'left',
      paddingLeft: 0,
    },
  }));
  const classes = useStyles();

  const {
    firstname,
    onChangeFirstName,
    lastname,
    onChangeLastName,
    gender,
    onChangeGender,
    fieldsError,
    inputValidation,
    setStoredDateOfBirth,
    storedDateOfBirth,
    setDateError,
    dateError,
    staticTexts,
    isValidForm,
    confirm,
    open,
    openOther,
    closeOther,
    other,
    onChangeOther,
    disabled,
  } = props;

  return (
    <>
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {staticTexts.PersonalInformationTitle}
      </Typography>
      <Typography variant="h2" component="h2" className={classes.pageSubTitle}>
        {staticTexts.PersonalInformationSubTitle}
      </Typography>
      <div
        className={
          classes.centerContent +
          ' ' +
          classes.alignContentCenter +
          ' ' +
          classes.pageContent
        }
      >
        <div className="form-item">
          <FormControl
            error={!!fieldsError.firstname}
            variant="filled"
            fullWidth
          >
            <InputLabel htmlFor="firstname">
              {staticTexts.PersonalInformationFirstNamePlaceholder}
            </InputLabel>
            <FilledInput
              id="firstname"
              className={classes.field}
              value={firstname}
              inputProps={{
                'data-id': 'personal-info-firstname',
              }}
              onChange={(e) => {
                onChangeFirstName(e);
                inputValidation(
                  e,
                  staticTexts.PersonalInformationFirstNamePlaceholder,
                  true,
                  'firstname'
                );
              }}
              onBlur={(e) => {
                inputValidation(
                  e,
                  staticTexts.PersonalInformationFirstNamePlaceholder,
                  true,
                  'firstname'
                );
              }}
            />
            <FormHelperText className={classes.marginHelper}>
              {fieldsError.firstname}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="form-item">
          <FormControl
            error={!!fieldsError.lastname}
            variant="filled"
            fullWidth
          >
            <InputLabel htmlFor="lastname">
              {staticTexts.PersonalInformationLastNamePlaceholder}
            </InputLabel>
            <FilledInput
              id="lastname"
              className={classes.field}
              value={lastname}
              inputProps={{
                'data-id': 'personal-info-lastname',
              }}
              onChange={(e) => {
                onChangeLastName(e);
                inputValidation(
                  e,
                  staticTexts.PersonalInformationLastNamePlaceholder,
                  true,
                  'lastname'
                );
              }}
              onBlur={(e) => {
                inputValidation(
                  e,
                  staticTexts.PersonalInformationLastNamePlaceholder,
                  true,
                  'lastname'
                );
              }}
            />
            <FormHelperText className={classes.marginHelper}>
              {fieldsError.lastname}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="form-item">
          <label htmlFor="gender">
            {staticTexts.PersonalInformationGenderLabel}
          </label>
          {!open && (
            <>
              <FormControl className={classes.genderControl} variant="filled">
                <RadioGroup
                  aria-label="gender"
                  id="gender"
                  name="gender"
                  value={gender}
                  onChange={onChangeGender}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        color="secondary"
                        inputProps={{
                          'data-id': 'personal-info-gender-m',
                        }}
                      />
                    }
                    value="M"
                    label={staticTexts.PersonalInformationGenderMale}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="secondary"
                        inputProps={{
                          'data-id': 'personal-info-gender-f',
                        }}
                      />
                    }
                    value="F"
                    label={staticTexts.PersonalInformationGenderFemale}
                  />
                </RadioGroup>
              </FormControl>
              <Button
                className={classes.buttonClean + ' ' + classes.buttonOther}
                disableElevation
                onClick={openOther}
                fullWidth
                size="large"
                data-id="personal-info-gender-expand"
              >
                {staticTexts.PersonalInformationGenderOtherShowBtn}
              </Button>
            </>
          )}
          {!!open && (
            <>
              <Button
                className={classes.buttonClean + ' ' + classes.buttonOther}
                disableElevation
                onClick={closeOther}
                fullWidth
                size="large"
                data-id="personal-info-gender-collapse"
              >
                {staticTexts.PersonalInformationGenderOtherHideBtn}
              </Button>
              <label htmlFor="other">
                {staticTexts.PersonalInformationGenderOtherLabel}
              </label>
              <FormControl
                error={!!fieldsError.other}
                variant="filled"
                fullWidth
              >
                <InputLabel htmlFor="other">
                  {staticTexts.PersonalInformationGenderOtherPlaceholder}
                </InputLabel>
                <FilledInput
                  id="other"
                  className={classes.field}
                  value={other}
                  inputProps={{
                    'data-id': 'personal-info-gender-other',
                  }}
                  onChange={(e) => {
                    onChangeOther(e);
                  }}
                />
                <FormHelperText className={classes.marginHelper}>
                  {fieldsError.other}
                </FormHelperText>
              </FormControl>
            </>
          )}
        </div>
        <div className={'form-item ' + classes.dateOfBirth}>
          <CustomDateInput
            setStoredDateOfBirth={setStoredDateOfBirth}
            storedDateOfBirth={storedDateOfBirth}
            setDateError={setDateError}
            dateError={dateError}
          />
        </div>
        <Button
          onClick={confirm}
          className={classes.button + ' ' + classes.buttonLast}
          disabled={!isValidForm || disabled}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          size="large"
          data-id="personal-info-confirm"
        >
          {staticTexts.PersonalInformationBtn}
        </Button>
      </div>
    </>
  );
};

export default PersonalInformationForm;
