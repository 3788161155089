import React, { useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import PhoneInput from 'react-phone-input-2';

import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import Preloader from '../../../Preloader';
import Link from '@mui/material/Link';
import { analytics } from '../../../../actions';
import { styles } from '../../../../styles';
import '../../../../styles/phoneInput.css';

const styleOverride = {};

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  return (
    <PhoneInput
      {...props}
      ref={ref}
      inputClass={'MuiInputBase-input MuiFilledInput-input'}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: true,
      }}
      country={'us'}
      enableSearch={true}
    />
  );
});

const PhoneForm = (props) => {
  const staticTexts = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles(() => ({
    ...styles.defaultFormStyles,
    ...styleOverride,
  }));
  const classes = useStyles();

  const {
    phone,
    isInvalid,
    onPhoneNumberChange,
    onPhoneLoginSubmit,
    error,
    inlineLoading,
    loadCaptcha,
  } = props;

  useEffect(() => {
    loadCaptcha();
    analytics.page('Phone Verification Form');

    // eslint-disable-next-line
  }, [])

  let recaptchaDisclamer = reactStringReplace(
    staticTexts.PhoneVerificationRecaptchaText,
    '@privacy',
    (match, i) => (
      <Link
        key={i * 111}
        href={staticTexts.PhoneVerificationRecaptchaTextPrivacyLink}
        target={'_blank'}
      >
        {staticTexts.PhoneVerificationRecaptchaTextPrivacy}
      </Link>
    )
  );

  recaptchaDisclamer = reactStringReplace(
    recaptchaDisclamer,
    '@terms',
    (match, i) => (
      <Link
        key={i * 222}
        href={staticTexts.PhoneVerificationRecaptchaTextTermsLink}
        target={'_blank'}
      >
        {staticTexts.PhoneVerificationRecaptchaTextTerms}
      </Link>
    )
  );

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <Typography variant="h1" component="h1" className={classes.pageTitle}>
        {staticTexts.PhoneVerificationTitle}
      </Typography>

      <Typography variant="h2" component="h2" className={classes.pageSubTitle}>
        {staticTexts.PhoneVerificationDescription}
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <FormControl
          error={!!error}
          variant="filled"
          fullWidth
          className={'phoneControl'}
        >
          <InputLabel htmlFor="formatted-text-mask-input">
            {staticTexts.PhoneVerificationPhoneLabel}
          </InputLabel>

          <FilledInput
            autoFocus
            value={phone}
            onChange={onPhoneNumberChange}
            id="formatted-text-mask-input"
            inputComponent={TextMaskCustom}
            variant="filled"
            data-id="phone"
          />

          {error && (
            <FormHelperText className={classes.marginHelper}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>

        {inlineLoading && (
          <Preloader title="Verifying your request." waitFor={90000} />
        )}

        <Button
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          color="primary"
          type="button"
          id={'phone-verification-button'}
          onClick={onPhoneLoginSubmit}
          disableElevation
          fullWidth
          data-id="phone-form-button"
          size="large"
        >
          {staticTexts.PhoneVerificationButton}
        </Button>
      </form>

      <div className={classes.phoneAnnotationWrapper}>
        <Typography
          variant="caption"
          component="div"
          className={classes.annotation}
        >
          {staticTexts.PhoneVerificationAnnotation}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          className={classes.annotation}
        >
          {recaptchaDisclamer}
        </Typography>
      </div>
    </Box>
  );
};

export default PhoneForm;
