import { useDispatch, useSelector } from 'react-redux';
import { analytics, db } from '../../../actions';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { Alert, AlertTitle } from '@mui/material';
import { styles } from '../../../styles';

const styleOverride = {
  buttonSmallSpaceTop: {
    marginTop: '2vh',
  },
};

const AlertDialog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const staticTexts = useSelector((state) => state.textsState.data);
  const customStyles = { ...styles.defaultAlertStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const errorPageDataAlert = useSelector((state) => state.errorState.alert);
  const errorPageDataDescTitle = useSelector(
    (state) => state.errorState.descTitle
  );
  const errorPageDataDesc = useSelector((state) => state.errorState.desc);
  const errorPageDataBtn = useSelector((state) => state.errorState.btn);

  const [open, setOpen] = useState(true);

  let errorTitle = errorPageDataAlert.title
    ? errorPageDataAlert.title
    : staticTexts.ErrorDefaultTitle;

  useEffect(() => {
    analytics.page('Error Alert - ' + errorTitle);

    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    setOpen(false);
    dispatch(db.clearErrorPage());
  };

  const clickBtn = () => {
    if (errorPageDataBtn.page) {
      history.push(errorPageDataBtn.page);
      handleClose();
    } else if (errorPageDataBtn.external) {
      window.open(errorPageDataBtn.external, '_blank');
      handleClose();
    } else if (errorPageDataBtn.windowClose) {
      window.close();
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      onClose={errorPageDataBtn.close ? handleClose : null}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.container}>
        {!errorPageDataDescTitle && (
          <Alert
            severity={
              errorPageDataAlert.type ? errorPageDataAlert.type : 'error'
            }
            className={classes.alert}
          >
            <AlertTitle className={classes.alertTitle}>{errorTitle}</AlertTitle>
            {errorPageDataAlert.desc && (
              <Typography gutterBottom>{errorPageDataAlert.desc}</Typography>
            )}
          </Alert>
        )}
        {errorPageDataDescTitle && (
          <Typography
            gutterBottom
            variant="h2"
            className={classes.title}
            component="h2"
          >
            {errorPageDataDescTitle}
          </Typography>
        )}
        {errorPageDataDesc && (
          <Typography gutterBottom className={classes.desc}>
            {errorPageDataDesc}
          </Typography>
        )}
        {errorPageDataBtn.title && (
          <Button
            onClick={clickBtn}
            className={
              errorPageDataBtn.signout && errorPageDataBtn.close
                ? classes.buttonRed
                : classes.button
            }
            type="submit"
            disableElevation
            fullWidth
            variant="contained"
            size="large"
          >
            {errorPageDataBtn.title}
          </Button>
        )}
        {errorPageDataBtn.signout && errorPageDataBtn.close && (
          <Button
            onClick={handleClose}
            className={
              classes.buttonOutlined + ' ' + classes.buttonSmallSpaceTop
            }
            type="submit"
            disableElevation
            fullWidth
            variant="contained"
            size="large"
          >
            {'No, go back'}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default AlertDialog;
