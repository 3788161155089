import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { db, helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import CaptureInProgress from './CaptureInProgress';
import BackButton from '../../Common/BackButton/BackButton';
import Preloader from '../../Preloader/Preloader';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { styles } from '../../../styles';
import { saveIdentification } from '../../../actions/helpers';
import getCustomRoute, {
  getAllIdentificationStatuses,
  isFullyVerified,
} from '../../../actions/getCustomRoute';

const IdScan = () => {
  const { doCreateIdVerificationSession } = db;

  const history = useHistory();
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.accountState.account);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const staticText = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles(() => ({
    ...styles.defaultFormStyles,
    ...styles.defaultIdScanStyles,
  }));
  const classes = useStyles();

  const [verificationInProcess, setVerificationInProcess] = useState(null);
  const [loading, setLoading] = useState(false);

  // Wait for results from Veriff.
  useEffect(() => {
    if (accountData) {
      const { veriffStatus } = getAllIdentificationStatuses(accountData);
      const verified = isFullyVerified(accountData);

      if (veriffStatus === 'pending') {
        setVerificationInProcess('finished');
      } else if (
        ['passed', 'failed'].indexOf(veriffStatus) !== -1 ||
        verified
      ) {
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  /**
   * Set Veriff Verification as incomplete.
   */
  useEffect(() => {
    saveIdentification({ type: 'veriff' }).catch((error) => {
      console.error(error.message);
    });
  }, []);

  // Open verification when we get verificationId.
  useEffect(() => {
    if (typeof sessionDB.data.verificationIdSessionId !== 'undefined') {
      setLoading(false);

      helpers
        .writeSession(
          {
            verificationIdSessionId: sessionDB.data.verificationIdSessionId,
          },
          sessionDB.id
        )
        .catch((error) => {
          helpers.handleError(error, dispatch);
        });

      doOpenVerificationUrl();
    }
    // eslint-disable-next-line
  }, [sessionDB])

  // Start verification.
  const startVerification = () => {
    setLoading(true);

    dispatch(doCreateIdVerificationSession());
  };

  const doOpenVerificationUrl = () => {
    createVeriffFrame({
      url: sessionDB.data.verificationIdSessionUrl,
      onEvent: function (msg) {
        switch (msg) {
          case MESSAGES.STARTED:
            setVerificationInProcess('started');
            break;
          case MESSAGES.FINISHED:
            setVerificationInProcess('finished');
            break;
          case MESSAGES.CANCELED:
            setVerificationInProcess(null);
            cancelVerification();
            break;
          default:
            break;
        }
      },
    });
  };

  const manualVerification = () => {
    history.push(routes.MANUAL_VERIFICATION);
  };

  // When user canceled verification.
  const cancelVerification = () => {
    history.push(routes.CONTACT_US);
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      {loading && (
        <Preloader
          title={staticText.IdScanLoading}
          errorTitle="Error loading Id Scan page"
        />
      )}

      {verificationInProcess && (
        <CaptureInProgress
          // doOpenVerificationUrl={doOpenVerificationUrl}
          classes={classes}
          staticText={staticText}
        />
      )}

      {!verificationInProcess && (
        <React.Fragment>
          <BackButton />
          <Typography variant="h1" component="h1">
            {staticText.IdScanTitle}
          </Typography>
          <Typography variant="h2" component="h2">
            {staticText.IdScanSubTitle}
          </Typography>
          <Typography variant="h2" component="h2">
            {staticText.IdScanSubTitle2}
          </Typography>
          <div className={classes.idContainer}>
            <div className={classes.card + ' card-driver'}>
              {staticText.IdScanDriversLabel}
            </div>
            <div className={classes.card + ' card-passport'}>
              {staticText.IdScanPassportLabel}
            </div>
            <div className={classes.card + ' card-permit'}>
              {staticText.IdScanPermitLabel}
            </div>
            <div className={classes.card + ' card-id'}>
              {staticText.IdScanIdCardLabel}
            </div>
          </div>
          <Button
            className={classes.button}
            disableElevation
            onClick={startVerification}
            fullWidth
            size="large"
            data-id="id-scan-btn"
          >
            {staticText.IdScanBtn}
          </Button>
        </React.Fragment>
      )}
      <Button
        className={classes.buttonCleanSmall}
        fullWidth
        onClick={manualVerification}
        size="large"
        data-id="id-scan-cancel"
      >
        {staticText.IdScanBtnCancel}
      </Button>
      <Typography className={classes.copyright} component="p">
        {staticText.IdScanCopyright}
      </Typography>
    </Box>
  );
};

export default IdScan;
