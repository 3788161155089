import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { styles } from '../../../../styles';

const styleOverride = {};

const CustomDateInput = (props) => {
  const { storedDateOfBirth, setStoredDateOfBirth, setDateError } = props;

  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const staticTexts = useSelector((state) => state.textsState.data);

  const [dateOfBirth, setDateOfBirth] = useState();
  const [error, setError] = useState(false);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'You need to be at least 13 years old';
      }
      case 'minDate': {
        return 'Please select a year prior to 1920';
      }

      case 'invalidDate': {
        return 'Please follow MM/DD/YYYY format';
      }

      default: {
        return '';
      }
    }
  }, [error]);

  useEffect(() => {
    if (storedDateOfBirth) {
      setDateOfBirth(dayjs(storedDateOfBirth, 'YYYY-MM-DD'));
    }
  }, [storedDateOfBirth]);

  return (
    <div className={'form-item ' + classes.codeItem}>
      <div className={'form-item ' + classes.dateOfBirth}>
        <label htmlFor="dateOfBirth">
          {staticTexts.PersonalInformationDateOfBirthLabel}
        </label>
        <DateField
          className={classes.dateField}
          inputProps={{
            'data-id': 'date-of-birth',
          }}
          label="Date of Birth"
          format="MM/DD/YYYY"
          value={dateOfBirth}
          onError={(newError) => setError(newError)}
          onChange={(newValue, context) => {
            if (context.validationError === null && newValue) {
              const newIsoValue = newValue.format('YYYY-MM-DD');
              setStoredDateOfBirth(newIsoValue);
              setDateOfBirth(newValue);
              setDateError(false);
            } else if (newValue) {
              setDateError(true);
            }
          }}
          slotProps={{
            textField: {
              variant: 'filled',
              helperText: errorMessage ? errorMessage : '',
            },
          }}
          minDate={dayjs('1920-01-01', 'YYYY-MM-DD')}
          maxDate={dayjs().subtract(13, 'y')}
        />
      </div>
    </div>
  );
};

export default CustomDateInput;
