export const relation = {
  veteran: {
    affinity: 'former',
    subgroup: null,
  },
  active_duty: {
    affinity: 'active',
    subgroup: 'forces',
  },
  academy: {
    affinity: 'active',
    subgroup: 'academy',
  },
  commissioned: {
    affinity: 'active',
    subgroup: 'commissioned',
  },
  relative: {
    affinity: 'family',
    subgroup: null,
  },
};
