import React, { useEffect, useState } from 'react';
import { analytics } from '../../../actions';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { vh } from '../../../styles/helper';
import UploadFile from './UploadFile';
import SuccessScreen from './SuccessScreen';
import FailedScreen from './FailedScreen';
import Typography from '@mui/material/Typography';
import Preloader from '../../Preloader';
import { styles } from '../../../styles';
import * as Sentry from '@sentry/browser';
import UploadFileRelative from './UploadFileRelative';
import getCustomRoute, {
  getAffinity,
  getAllIdentificationStatuses,
  isFullyVerified,
} from '../../../actions/getCustomRoute';
import { storageHelper } from '../../../firebase/firebase';

const ManualVerification = () => {
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticText = useSelector((state) => state.textsState.data);
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    h1: {
      marginLeft: vh('-10px'),
      marginRight: vh('-10px'),
    },
    h2Wider: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        marginLeft: vh('-30px'),
        marginRight: vh('-30px'),
      },
    },
    higherContent: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        marginTop: vh('-15px'),
        marginBottom: vh('-15px'),
      },
    },
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('50px'),
        paddingBottom: vh('20px'),
      },
    },
    listItem: {
      fontWeight: '400',
      textAlign: 'center',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    previewChip: {
      minWidth: vh('150px'),
      maxWidth: vh('150px'),
    },
    dropzone: {
      background: 'transparent',
    },
    dropzoneAnnotation: {
      position: 'relative',
      height: vh('80px'),
      marginTop: vh('-100px'),
      marginBottom: vh('20px'),
      paddingLeft: vh('16px'),
      paddingRight: vh('16px'),
      color: '#677796',
      fontSize: vh('12px'),
      lineHeight: vh('14px'),
      textAlign: 'center',
      zIndex: '-1',
      '& p': {
        marginTop: '0',
        marginBottom: '1em',
      },
    },
  }));
  const classes = useStyles();
  const [step, setStep] = useState('upload');
  const [fileCount, setFileCount] = useState(0);
  const [files, setFiles] = useState(0);

  /**
   * Helper to show done component when needed
   * and redirect on success or failure.
   */
  useEffect(() => {
    if (accountData) {
      // Get SDU Military status.
      const { sduMilitaryStatus } = getAllIdentificationStatuses(accountData);
      const verified = isFullyVerified(accountData);

      if (sduMilitaryStatus === 'pending') {
        setStep('done');
      } else if (
        ['passed', 'failed'].indexOf(sduMilitaryStatus) !== -1 ||
        verified
      ) {
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  const upload = () => {
    setStep('inprogress');

    let filesLeft = files.length;

    // Upload the file and metadata
    const uploadFile = () => {
      const currentFile = files[filesLeft - 1];
      const metadata = {
        customMetadata: {
          uid: authUser.uid,
          memberId: accountData.memberId,
          uploaded: new Date(),
          name: currentFile.name,
          path: currentFile.path,
          size: currentFile.size,
          lastModified: currentFile.lastModified,
          type: currentFile.type,
        },
      };
      const { storage, ref, uploadBytes } = storageHelper;
      const storageRef = ref(storage, authUser.uid + '/' + currentFile.path);
      // Upload the file and metadata
      return uploadBytes(storageRef, currentFile, metadata)
        .then(() => {
          // decrement files left value.
          filesLeft--;

          if (filesLeft !== 0) {
            return uploadFile();
          } else {
            setStep('done');
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          // Track 'Document Upload Error' segment event.
          analytics.track('Document Upload Failed', {
            errorMessage: error.message,
            fileName: currentFile.name,
            fileSize: currentFile.size.toString(),
            fileMimeType: currentFile.type,
            memberId: accountData.memberId,
            context: {
              app: {
                name: 'VerifyApp',
                namespace: 'components/Verification/ManualVerification',
                build: process.env.REACT_APP_BUILD,
                version: process.env.REACT_APP_VERSION,
              },
            },
          });
          setStep('fail');
        });
    };

    uploadFile();
  };

  const fileUpload = (refFiles) => {
    setFileCount(refFiles.length);
    setFiles(refFiles);
  };
  const resetState = () => {
    setStep('upload');
  };
  const contactUs = () => {
    history.push(routes.CONTACT_US);
  };

  let documents = staticText.ManualVerificationDocuments;
  const affinity = getAffinity(accountData);
  if (!affinity) {
    return (
      <Preloader
        title={staticText.MilitaryCredentialsLoading}
        errorTitle="Error loading Military Credentials page"
      />
    );
  } else {
    return (
      <Box
        p={{ xs: 2, md: 10 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.box + ' ' + classes.boxHeigher}
      >
        {step === 'upload' && affinity !== 'relative' && (
          <UploadFile
            fileUpload={fileUpload}
            classes={classes}
            fileCount={fileCount}
            documents={documents}
            upload={upload}
            staticText={staticText}
          />
        )}
        {step === 'upload' && affinity === 'relative' && (
          <UploadFileRelative
            fileUpload={fileUpload}
            classes={classes}
            fileCount={fileCount}
            upload={upload}
            staticText={staticText}
          />
        )}
        {step === 'inprogress' && (
          <React.Fragment>
            <Typography variant="h1" component="h1" className={classes.onTop}>
              {staticText.ManualVerificationLoaderInProgress}
            </Typography>
            <Preloader
              waitFor={180000}
              errorTitle="Error loading Manual Verification page"
            />
          </React.Fragment>
        )}
        {step === 'done' && (
          <SuccessScreen
            classes={classes}
            contactUs={contactUs}
            staticText={staticText}
          />
        )}
        {step === 'fail' && (
          <FailedScreen
            classes={classes}
            contactUs={contactUs}
            resetState={resetState}
            staticText={staticText}
          />
        )}
      </Box>
    );
  }
};

export default ManualVerification;
