import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ActiveServiceHistory = (props) => {
  const {
    rank,
    paygrade,
    unit,
    ranks,
    paygrades,
    units,
    handleOptionChangeRank,
    handleOptionChangePaygrade,
    handleOptionChangeUnit,
    staticText,
  } = props;

  return (
    <React.Fragment>
      <div className="form-item-select">
        <label htmlFor="rank">{staticText.ActiveServiceHistoryRankLabel}</label>
        <Autocomplete
          value={rank}
          options={ranks}
          getOptionLabel={(option) => option.label}
          onChange={handleOptionChangeRank}
          ListboxProps={{ 'data-id': 'active-credentials-rank-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.ActiveServiceHistoryRankPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'active-credentials-rank',
              }}
            />
          )}
        />
      </div>
      <div className="form-item-select">
        <label htmlFor="paygrade">
          {staticText.ActiveServiceHistoryPaygradeLabel}
        </label>
        <Autocomplete
          value={paygrade}
          options={paygrades}
          getOptionLabel={(option) => option.label}
          onChange={handleOptionChangePaygrade}
          ListboxProps={{ 'data-id': 'active-credentials-paygrade-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.ActiveServiceHistoryPaygradePlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'active-credentials-paygrade',
              }}
            />
          )}
        />
      </div>
      <div className="form-item-select">
        <label htmlFor="unit">{staticText.ActiveServiceHistoryUnitLabel}</label>
        <Autocomplete
          value={unit}
          options={units}
          getOptionLabel={(option) => option.label}
          onChange={handleOptionChangeUnit}
          ListboxProps={{ 'data-id': 'active-credentials-unit-list' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={staticText.ActiveServiceHistoryUnitPlaceholder}
              inputProps={{
                ...params.inputProps,
                'data-id': 'active-credentials-unit',
              }}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default ActiveServiceHistory;
